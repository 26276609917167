import React from 'react';

const IconFavorite = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0.5 6.67434C0.5 4.62505 1.52303 3.02759 2.90265 2.289C4.26481 1.55977 6.04116 1.63101 7.67111 3.05448C7.8595 3.21901 8.1405 3.21901 8.32889 3.05448C9.95884 1.63101 11.7352 1.55977 13.0973 2.289C14.477 3.02758 15.5 4.62505 15.5 6.67434C15.5 8.6624 13.9836 10.6988 12.17 12.2917C11.2787 13.0746 10.3477 13.7216 9.56405 14.1703C9.172 14.3948 8.8235 14.5658 8.54064 14.6791C8.2412 14.799 8.06587 14.8333 8 14.8333C7.93413 14.8333 7.7588 14.799 7.45937 14.6791C7.1765 14.5658 6.828 14.3948 6.43595 14.1703C5.65235 13.7216 4.72134 13.0746 3.82996 12.2916C2.01643 10.6988 0.5 8.66239 0.5 6.67434Z"
         stroke="currentColor"
         strokeLinejoin="round"
      />
      <path
         d="M4.9761 4.1647C3.64418 4.45098 3.11647 5.47579 3.21652 6.75128"
         stroke="currentColor"
         strokeLinecap="round"
      />
   </svg>
);

export default IconFavorite;
