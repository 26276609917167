import React from 'react';

const IconForbidden = () => (
   <svg width={120} height={120} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#forbidden-clip0)">
         <path
            d="M57.1716 38.7868C58.7337 37.2247 61.2663 37.2247 62.8284 38.7868L80.5061 56.4645C82.0682 58.0266 82.0682 60.5592 80.5061 62.1213L46.565 96.0624C45.0029 97.6245 42.4702 97.6245 40.9081 96.0624L23.2304 78.3848C21.6684 76.8227 21.6684 74.29 23.2304 72.7279L57.1716 38.7868Z"
            fill="#F2F2F2"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.4645 38.0797C58.4171 36.1271 61.5829 36.1271 63.5355 38.0797L81.2132 55.7574C83.1658 57.71 83.1658 60.8758 81.2132 62.8285L47.2721 96.7696C45.3195 98.7222 42.1536 98.7222 40.201 96.7696L22.5233 79.0919C20.5707 77.1393 20.5707 73.9735 22.5233 72.0208L56.4645 38.0797ZM62.1213 39.4939C60.9497 38.3224 59.0503 38.3224 57.8787 39.4939L23.9376 73.4351C22.766 74.6066 22.766 76.5061 23.9376 77.6777L41.6152 95.3554C42.7868 96.5269 44.6863 96.5269 45.8579 95.3554L79.799 61.4142C80.9706 60.2427 80.9706 58.3432 79.799 57.1716L62.1213 39.4939Z"
            fill="#828282"
         />
         <path
            d="M81.2132 34.5442C81.9943 33.7632 83.2606 33.7632 84.0417 34.5442L91.1127 41.6153C91.8938 42.3963 91.8938 43.6627 91.1127 44.4437L79.799 55.7574L69.8995 45.8579L81.2132 34.5442Z"
            fill="#F2F2F2"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M80.5061 33.837C81.6777 32.6654 83.5772 32.6654 84.7488 33.837L91.8198 40.9081C92.9914 42.0797 92.9914 43.9792 91.8198 45.1507L79.799 57.1715L68.4853 45.8578L80.5061 33.837ZM83.3345 35.2512C82.944 34.8607 82.3109 34.8607 81.9203 35.2512L71.3137 45.8578L79.799 54.3431L90.4056 43.7365C90.7961 43.346 90.7961 42.7128 90.4056 42.3223L83.3345 35.2512Z"
            fill="#828282"
         />
         <path
            d="M91.1128 28.8874C91.8938 28.1063 93.1601 28.1063 93.9412 28.8874L96.7696 31.7158C97.5507 32.4968 97.5507 33.7632 96.7696 34.5442L90.4057 40.9082L84.7488 35.2513L91.1128 28.8874Z"
            fill="#F2F2F2"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M90.4057 28.1803C91.5772 27.0087 93.4767 27.0087 94.6483 28.1803L97.4767 31.0087C98.6483 32.1803 98.6483 34.0798 97.4767 35.2513L90.4057 42.3224L83.3346 35.2513L90.4057 28.1803ZM93.2341 29.5945C92.8436 29.204 92.2104 29.204 91.8199 29.5945L86.163 35.2513L90.4057 39.494L96.0625 33.8371C96.453 33.4466 96.453 32.8134 96.0625 32.4229L93.2341 29.5945Z"
            fill="#828282"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.8162 98.1838C42.9045 119.272 77.0955 119.272 98.1838 98.1838C119.272 77.0955 119.272 42.9046 98.1838 21.8163C77.0955 0.727959 42.9045 0.727959 21.8162 21.8163C0.727921 42.9046 0.727924 77.0955 21.8162 98.1838ZM20.402 99.598C42.2714 121.467 77.7286 121.467 99.598 99.598C121.467 77.7287 121.467 42.2714 99.598 20.4021C77.7286 -1.4673 42.2714 -1.4673 20.402 20.4021C-1.46734 42.2714 -1.46734 77.7287 20.402 99.598Z"
            fill="#828282"
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.9411 103.841L16.1594 26.0589L17.5736 24.6447L95.3554 102.426L93.9411 103.841Z"
            fill="#828282"
         />
      </g>
      <defs>
         <clipPath id="forbidden-clip0">
            <rect width={120} height={120} fill="white" />
         </clipPath>
      </defs>
   </svg>
);

export default IconForbidden;
