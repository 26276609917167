import React, { PureComponent } from 'react';

class IconCart16 extends PureComponent {
   render() {
      return (
         <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <rect x={1} y="0.5" width={14} height={15} rx="2.5" stroke="black" />
            <path
               d="M11.5 4.5C11.5 6.433 9.933 8 8 8C6.067 8 4.5 6.433 4.5 4.5"
               stroke="black"
               strokeMiterlimit={10}
               strokeLinecap="round"
               strokeLinejoin="round"
            />
         </svg>
      );
   }
}

export default IconCart16;