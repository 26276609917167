import React from 'react';

const IconUserNotification = () => (
   <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M12 10.52C11.59 10.52 11.25 10.18 11.25 9.77V6.44C11.25 6.03 11.59 5.69 12 5.69C12.41 5.69 12.75 6.03 12.75 6.44V9.77C12.75 10.19 12.41 10.52 12 10.52Z"
         fill="currentColor"
      />
      <path
         d="M12.02 20.35C9.43999 20.35 6.86999 19.94 4.41999 19.12C3.50999 18.82 2.81999 18.17 2.51999 17.35C2.21999 16.53 2.31999 15.59 2.80999 14.77L4.07999 12.65C4.35999 12.18 4.60999 11.3 4.60999 10.75V8.64999C4.60999 4.55999 7.92999 1.23999 12.02 1.23999C16.11 1.23999 19.43 4.55999 19.43 8.64999V10.75C19.43 11.29 19.68 12.18 19.96 12.65L21.23 14.77C21.7 15.55 21.78 16.48 21.47 17.33C21.16 18.18 20.48 18.83 19.62 19.12C17.17 19.95 14.6 20.35 12.02 20.35ZM12.02 2.74999C8.75999 2.74999 6.10999 5.39999 6.10999 8.65999V10.76C6.10999 11.57 5.78999 12.74 5.36999 13.43L4.09999 15.56C3.83999 15.99 3.77999 16.45 3.92999 16.85C4.07999 17.25 4.41999 17.55 4.89999 17.71C9.49999 19.24 14.56 19.24 19.16 17.71C19.59 17.57 19.92 17.25 20.07 16.83C20.23 16.41 20.18 15.95 19.95 15.56L18.68 13.44C18.26 12.75 17.94 11.58 17.94 10.77V8.66999C17.93 5.39999 15.28 2.74999 12.02 2.74999Z"
         fill="currentColor"
      />
      <path
         d="M12 22.9C10.93 22.9 9.88004 22.46 9.12004 21.7C8.36004 20.94 7.92004 19.89 7.92004 18.82H9.42004C9.42004 19.5 9.70004 20.16 10.18 20.64C10.66 21.12 11.32 21.4 12 21.4C13.42 21.4 14.58 20.24 14.58 18.82H16.08C16.08 21.07 14.25 22.9 12 22.9Z"
         fill="currentColor"
      />
   </svg>
);

export default IconUserNotification;
