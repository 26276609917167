import constant from './constant';
import Cookies from 'js-cookie';
// import FingerprintJS from '@fingerprintjs/fingerprintjs'

const getToken = () => {
   let tokenValue = Cookies.get(constant.TOKEN_VARIABLE, {
      // domain: constant.DOMAIN_NAME,
   });
   if (tokenValue) {
      return `Bearer ${tokenValue}`;
   }
   return null;
};

// const _fpPromise = FingerprintJS.load();
const getDeviceId = async () => {
   const savedDeviceId = getCookie(constant.DEVICE_ID_VARIABLE);
   if (savedDeviceId) {
      return savedDeviceId;
   }
   if (typeof window !== `undefined`) {
      const FingerprintJS = require('@fingerprintjs/fingerprintjs');
      const _fpPromise = FingerprintJS.load();
      const fp = await _fpPromise;
      const result = await fp.get();
      setCookie(constant.DEVICE_ID_VARIABLE, result.visitorId);
      return result.visitorId;
   }
   return '';
};

const setCookie = async (name, value) => {
   if (name) {
      await Cookies.set(name, value, {
         sameSite: 'strict',
         secure: true,
         expires: 90,
         // path: '',
         // domain: constant.DOMAIN_NAME,
      });
   }
};
const getCookie = (name) => {
   return Cookies.get(name, {
      // domain: constant.DOMAIN_NAME,
   });
};

const removeCookie = (name) => {
   if (name) {
      Cookies.remove(name, {
         // path: '',
         // domain: constant.DOMAIN_NAME,
      });
   }
};

const clearUserData = () => {
   removeCookie(constant.TOKEN_VARIABLE);
};

export const tokenUtil = {
   getToken,
   getDeviceId,

   setCookie,
   getCookie,
   removeCookie,
   clearUserData,
};
