import React, { useEffect, useMemo, useState } from 'react';
import Loadable from 'react-loadable';
import ErrorBoundary from '../views/error/ErrorBoundary/ErrorBoundary';
import Header from '../views/default-layout/header/Header';
import SEO from '@components/SEO';
import RootLayout from './RootLayout';
import BreakpointProvider from '@components/Breakpoint/BreakpointProvider';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import constant from '@utils/constant';
import { getAnalytics, getFirebase, getMessaging } from '@config/firebaseConfig';
import { notificationService } from '@services/notificationService';
import { storeDispatcher } from '@store/dispatcher';
import BackgroundLoader from '@components/BackgroundLoader';
import { Config } from '../config';

const publishableKey = constant.STRIPE_PUBLIC_KEY;

const stripeElmOptions = {
   locale: 'en',
};

let stripePromise;
const getStripe = () => {
   if (!stripePromise) {
      stripePromise = loadStripe(publishableKey);
   }
   return stripePromise;
};

const Layout = ({ children, pageContext, location }) => {
   const [dataNotify, setDataNotify] = useState(null);

   useEffect(() => {
      const prepareFWS = () => {
         try {
            const appPromise = import('firebase/app');
            const firebaseAnalyticsPromise = import('firebase/analytics');
            const firebaseMessagingPromise = import('firebase/messaging');
            Promise.all([
               appPromise,
               firebaseAnalyticsPromise,
               firebaseMessagingPromise,
            ]).then(([app, firebaseAnalytics, firebaseMessaging]) => {
               getFirebase(app.default);
               const analytics = getAnalytics(firebaseAnalytics);
               const messaging = getMessaging();
               if (messaging) {
                  Notification.requestPermission().then((permission) => {
                     if (permission === 'granted') {
                        console.log('Notification permission granted.');
                        messaging
                           .getToken({
                              vapidKey: Config.FCM_PUBLIC_VAPI_KEY,
                           })
                           .then((currentToken) => {
                              if (currentToken) {
                                 console.log(currentToken);
                                 storeDispatcher.setDeviceToken(currentToken);
                                 // Send the token to your server and update the UI if necessary
                                 // ...
                              } else {
                                 // Show permission request UI
                                 console.log(
                                    'No registration token available. Request permission to generate one.'
                                 );
                              }
                           })
                           .catch((err) => {
                              console.log(
                                 'An error occurred while retrieving token. ',
                                 err
                              );
                              // ...
                           });
                     } else {
                        console.log('Unable to get permission to notify.');
                     }
                  });
                  messaging.onMessage((payload) => {
                     notificationService.fetchUserNotificationsAmount();
                     if (payload?.notification) {
                        setDataNotify(payload.notification);
                     }
                  });
               }
            });
         } catch (err) {
            console.log(err);
         }
      };
      prepareFWS();
   }, []);

   const content = useMemo(() => {
      if (pageContext.layout === 'admin') {
         const AdminLayout = Loadable({
            loader: () => import('../views_admin/AdminLayout'),
            loading: () => null,
         });
         return (
            <>
               <SEO title="Admin" />
               <AdminLayout pathName={location.pathname}>
                  {children && children}
               </AdminLayout>
            </>
         );
      } else if (pageContext.layout === 'profile') {
         const ProfileLayout = Loadable({
            loader: () => import('./ProfileLayout'),
            loading: () => null,
         });
         return (
            <>
               <Header />
               <main className="main">
                  <div className="layout-wrapper">
                     <ProfileLayout pathName={location.pathname}>
                        {children && children}
                     </ProfileLayout>
                  </div>
               </main>
            </>
         );
      }
      return (
         <>
            <Header />
            <main className="main">
               <div className="layout-wrapper">{children && children}</div>
            </main>
         </>
      );
   }, [children, pageContext, location]);

   return (
      <BackgroundLoader location={location}>
         <Elements stripe={getStripe()} options={stripeElmOptions}>
            <BreakpointProvider>
               <ErrorBoundary location={location}>
                  <RootLayout location={location}>{content}</RootLayout>
               </ErrorBoundary>
            </BreakpointProvider>
         </Elements>
      </BackgroundLoader>
   );
};

export default Layout;
