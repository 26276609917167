import axios, { AxiosRequestConfig, Method, CancelTokenSource } from 'axios';
import qs from 'qs';
import { Config } from '../../config';
import { endpoints } from '../../constants/endpoints';
import { tokenUtil } from '../../utils/token';
import Response from './Response';

export default class Request {
   static defaultConfig = {
      headers: {
         'Content-Type': 'application/json; charset=utf-8',
         Authorization: '',
      },
      paramsSerializer: (params: object) => qs.stringify(params),
   };

   public static post = (action: string, config: AxiosRequestConfig) => {
      return Request.init('post', action, config);
   };

   public static get = (action: string, config: AxiosRequestConfig) => {
      return Request.init('get', action, config);
   };

   public static put = (action: string, config: AxiosRequestConfig) => {
      return Request.init('put', action, config);
   };

   public static delete = (action: string, config: AxiosRequestConfig) => {
      return Request.init('delete', action, config);
   };

   private static init = (method: Method, action: string, config: AxiosRequestConfig) => {
      const requestConfig: AxiosRequestConfig = Request.setConfig(method, config);
      const canceller = axios.CancelToken.source();

      return (dispatch: any): CancelTokenSource => {
         dispatch(Request.onPending(action, config?.data));

         try {
            Request.send(requestConfig)(action)
               .then(Response.dispatchResponse(dispatch, action))
               .catch(Response.dispatchError(dispatch, action));
         } catch (err: any) {
            Response.catchError(dispatch, err);
         } finally {
            canceller.cancel();
         }

         return canceller;
      };
   };

   private static setConfig = (method: Method, config: AxiosRequestConfig) => {
      const defaultConfig = Request.defaultConfig;
      const token = tokenUtil.getToken();
      if (token) {
         defaultConfig.headers['Authorization'] = token;
      }
      return {
         ...Request.defaultConfig,
         ...config,
         method,
      };
   };

   private static send = (config: AxiosRequestConfig) => (action: string) => {
      return axios({
         url: `${Config.API_URL}/${endpoints[`${action}__ENDPOINT`]}`,
         ...config,
      });
   };

   private static onPending = (action: string, data?: any) => {
      return {
         type: `${action}__PENDING`,
         payload: data,
      };
   };
}
