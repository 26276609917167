import * as actions from './actions';

const GET_ME = 'users/me';

const GET_CATEGORIES_MATRIX = 'categories/matrix';
const GET_PRODUCTS_MATRIX = 'products/matrix';
const GET_TAGS_MATRIX = 'tags/matrix';
const GET_PRO_OPTION_TYPES_MATRIX = 'products/options/types/matrix';
const GET_PRICE_TYPES_MATRIX = 'products/prices/types/matrix';
const GET_PRODUCT_MATRIX = 'products/matrix';
const GET_CART_DETAILS = 'carts/details';
const ADD_TO_CART = 'carts/add';
const UPDATE_CART_ITEM = 'carts/items/update';
const DELETE_CART_ITEM = 'carts/items/delete';
const GET_USERS_MATRIX = 'users/matrix';
const GET_USER_NOTIFICATIONS = 'notifications/user/matrix';
const FETCH_POPUP_USER_NOTIFICATIONS = 'notifications/user/matrix';
const GET_USER_NOTIFICATIONS_AMOUNT = 'notifications/user/amounts';
const GET_FEEDBACK_CATEGORIES_MATRIX = 'feedbacks/categories/matrix';
const GET_CONFIGURATION = 'configurations/details';

export const endpoints = {
   [`${actions.GET_ME}__ENDPOINT`]: GET_ME,

   [`${actions.FETCH_CATEGORIES}__ENDPOINT`]: GET_CATEGORIES_MATRIX,
   [`${actions.FETCH_PRODUCTS}__ENDPOINT`]: GET_PRODUCTS_MATRIX,
   [`${actions.FETCH_TAGS}__ENDPOINT`]: GET_TAGS_MATRIX,
   [`${actions.FETCH_PRO_OPTION_TYPES}__ENDPOINT`]: GET_PRO_OPTION_TYPES_MATRIX,
   [`${actions.FETCH_PRICE_TYPES}__ENDPOINT`]: GET_PRICE_TYPES_MATRIX,
   [`${actions.FETCH_HOME_NEW_PRODUCTS}__ENDPOINT`]: GET_PRODUCT_MATRIX,
   [`${actions.FETCH_HOME_BEST_SELLERS}__ENDPOINT`]: GET_PRODUCT_MATRIX,
   [`${actions.FETCH_CART}__ENDPOINT`]: GET_CART_DETAILS,
   [`${actions.ADD_TO_CART}__ENDPOINT`]: ADD_TO_CART,
   [`${actions.UPDATE_CART_ITEM}__ENDPOINT`]: UPDATE_CART_ITEM,
   [`${actions.DELETE_CART_ITEM}__ENDPOINT`]: DELETE_CART_ITEM,
   [`${actions.FETCH_USERS}__ENDPOINT`]: GET_USERS_MATRIX,
   [`${actions.FETCH_USER_NOTIFICATIONS}__ENDPOINT`]: GET_USER_NOTIFICATIONS,
   [`${actions.FETCH_POPUP_USER_NOTIFICATIONS}__ENDPOINT`]: FETCH_POPUP_USER_NOTIFICATIONS,
   [`${actions.FETCH_USER_NOTIFICATIONS_AMOUNT}__ENDPOINT`]: GET_USER_NOTIFICATIONS_AMOUNT,
   [`${actions.FETCH_FEEDBACK_CATEGORIES}__ENDPOINT`]: GET_FEEDBACK_CATEGORIES_MATRIX,
   [`${actions.FETCH_CONFIGURATION}__ENDPOINT`]: GET_CONFIGURATION,
};
