import React, { PureComponent } from 'react';

class IconLogout extends PureComponent {
   render() {
      return (
         <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M8 0V8"
               stroke="#FF5F73"
               strokeMiterlimit={10}
               strokeLinejoin="round"
            />
            <path
               d="M12.5 2C14.3217 3.36831 15.5 5.54682 15.5 8.00055C15.5 12.1427 12.1421 15.5005 8 15.5005C3.85786 15.5005 0.5 12.1427 0.5 8.00055C0.5 5.54715 1.67801 3.36889 3.49927 2.00055"
               stroke="#FF5F73"
            />
         </svg>
      );
   }
}

export default IconLogout;