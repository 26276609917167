import React from 'react';

const IconNotification = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M15.5 10C15.5 11.2137 14.7141 12.0775 13.3077 12.6635C11.8953 13.2519 9.97092 13.5 7.99998 13.5C6.02904 13.5 4.10463 13.2519 2.69231 12.6635C1.28591 12.0775 0.5 11.2137 0.5 10C0.5 9.45567 0.608543 9.06908 0.773282 8.74698C0.943673 8.41382 1.18465 8.1271 1.4958 7.78314C1.53704 7.73754 1.57915 7.69134 1.62198 7.64435C2.22589 6.98175 2.97307 6.16194 3.47434 4.65811C3.96115 3.1977 4.55133 2.16191 5.27663 1.4924C5.987 0.836662 6.86173 0.5 7.99998 0.5C9.13823 0.5 10.013 0.836662 10.7234 1.4924C11.4487 2.16191 12.0389 3.1977 12.5257 4.65811C13.0269 6.16194 13.7741 6.98174 14.378 7.64435C14.4208 7.69134 14.463 7.73754 14.5042 7.78314C14.8154 8.1271 15.0563 8.41382 15.2267 8.74698C15.3915 9.06908 15.5 9.45567 15.5 10Z"
         stroke="currentColor"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path d="M6.5 15.5H9.5" stroke="currentColor" strokeLinecap="round" />
   </svg>
);

export default IconNotification;