import IconMenu2 from '@icons/IconMenu2';
import constant from '@utils/constant';
import { functionUtils } from '@utils/function';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { memo, useEffect, useState } from 'react';

const MbMenu = memo(() => {
   const [isOpen, setIsOpen] = useState(false);

   useEffect(() => {
      if (isOpen) {
         functionUtils.setModalOpen(true);
      } else {
         functionUtils.setModalOpen(false);
      }

      return () => {
         functionUtils.setModalOpen(false);
      };
   }, [isOpen]);

   return (
      <>
         <div
            className={`mb-menu ${isOpen ? 'open' : ''}`}
            onClick={() => setIsOpen((v) => !v)}
         >
            <IconMenu2 />
         </div>
         <div
            className={classNames('mb-menu__drawer', {
               open: isOpen,
            })}
         >
            <div className="mb-menu__item-list">
               <Link
                  className="mb-menu__item"
                  to={constant.ROUTE_PRODUCTS}
                  onClick={() => setIsOpen(false)}
               >
                  <span>Products</span>
               </Link>
               <Link
                  className="mb-menu__item"
                  to={constant.ROUTE_CONTACT}
                  onClick={() => setIsOpen(false)}
               >
                  <span>Contact</span>
               </Link>
            </div>
         </div>
      </>
   );
});

export default MbMenu;
