import React, { PureComponent } from 'react';

class IconFacebook extends PureComponent {
   render() {
      return (
         <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M16 8.04801C16.0003 6.5191 15.5625 5.0221 14.7385 3.73429C13.9144 2.44647 12.7385 1.42177 11.3501 0.781514C9.96173 0.141257 8.41893 -0.0877379 6.90442 0.121642C5.38991 0.331023 3.96712 0.970009 2.80451 1.96294C1.6419 2.95587 0.788165 4.26117 0.344396 5.72426C-0.0993733 7.18736 -0.114591 8.74698 0.300544 10.2185C0.71568 11.6899 1.54378 13.0116 2.68679 14.0271C3.82981 15.0425 5.23986 15.7091 6.75 15.948V10.36H4.719V8.04801H6.75V6.28501C6.70627 5.87346 6.7536 5.45732 6.88863 5.0661C7.02366 4.67488 7.24309 4.31815 7.53136 4.02119C7.81964 3.72423 8.1697 3.49432 8.55674 3.34774C8.94379 3.20116 9.35833 3.14151 9.771 3.17301C10.371 3.18089 10.9696 3.23303 11.562 3.32901V5.30001H10.554C10.3823 5.27756 10.2077 5.294 10.0432 5.34813C9.87874 5.40225 9.72853 5.49267 9.6037 5.6127C9.47887 5.73272 9.38263 5.87928 9.3221 6.04153C9.26157 6.20377 9.23829 6.37755 9.254 6.55001V8.05001H11.473L11.118 10.362H9.25V15.953C11.1322 15.6553 12.8464 14.6954 14.0838 13.2462C15.3213 11.797 16.0008 9.95365 16 8.04801Z"
               fill="currentColor"
            />
         </svg>
      );
   }
}

export default IconFacebook;