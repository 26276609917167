import React, { memo, useEffect } from 'react';
import SEO from '../components/Common/SEO';
import Header from '@views/default-layout/header/Header';
import Content404 from '@views/default-layout/404';
import Footer from '@views/default-layout/footer/Footer';
import { functionUtils } from '@utils/function';

const PageNotFound = memo(() => {
   useEffect(() => {
      functionUtils.setModalOpen(true);
      return () => {
         functionUtils.setModalOpen(false);
      };
   }, []);

   return (
      <div className="vgz-error-page">
         <SEO title="Page not found" />
         <Header />
         <main className="main">
            <div className="layout-wrapper">
               <Content404 />
               <Footer />
            </div>
         </main>
      </div>
   );
});

export default PageNotFound;
