import React, { memo, useEffect } from 'react';
import Header from '@views/default-layout/header/Header';
import Footer from '@views/default-layout/footer/Footer';
import { functionUtils } from '@utils/function';
import SEO from '@components/SEO';
import IconCookiesDisabled from '@icons/IconCookiesDisabled';

const PageDisabledCookies = memo(() => {
   useEffect(() => {
      functionUtils.setModalOpen(true);
      return () => {
         functionUtils.setModalOpen(false);
      };
   }, []);

   return (
      <div className="vgz-error-page">
         <SEO title="Cookies have been disabled" />
         <Header />
         <main className="main">
            <div className="layout-wrapper">
               <div className="page-error">
                  <div className="content">
                     <IconCookiesDisabled />
                     <div className="msg">
                        <label>Cookies are disabled</label>
                        <span>
                           Your browser has cookies disabled.
                           <br />
                           Make sure your cookies are enabled and try again.&nbsp;
                           <a
                              href="https://support.google.com/accounts/answer/61416"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: '#2171DD' }}
                           >
                              Learn more
                           </a>
                        </span>
                     </div>
                  </div>
               </div>
               <Footer />
            </div>
         </main>
      </div>
   );
});

export default PageDisabledCookies;
