import { Link } from 'gatsby';
import React, { PureComponent } from 'react';
import Logo from '../../../components/Icons/Logo';
import constant from '../../../utils/constant';
import CartCount from './CartCount';
import MbMenu from './MbMenu';
import Breakpoint from '@components/Breakpoint/Breakpoint';
import Notifications from './Notifications';

class Header extends PureComponent {
   render() {
      return (
         <div className="header">
            <div className="layout-wrapper">
               <div className="header-container">
                  <Breakpoint md down>
                     <MbMenu />
                  </Breakpoint>
                  <Link className="logo" to={constant.ROUTE_HOME}>
                     <Logo />
                  </Link>
                  <div className="right-menu">
                     <Breakpoint lg up>
                        <Link className="menu-item" to={constant.ROUTE_CONTACT}>
                           <span>Contact</span>
                        </Link>
                        <Link className="menu-item" to={constant.ROUTE_PRODUCTS}>
                           <span>Products</span>
                        </Link>
                        <Notifications />
                     </Breakpoint>
                     <CartCount />
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default Header;
