import IconPageNotFound from '@icons/IconPageNotFound';
import React, { memo } from 'react';

const Content404 = memo(() => {
   return (
      <div className="page-error">
         <div className="content">
            <IconPageNotFound />
            <div className="msg">
               <label>404</label>
               <span>Page not found!</span>
            </div>
         </div>
      </div>
   );
});

export default Content404;
