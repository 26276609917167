import { combineReducers } from 'redux';
import categoriesReducer from './admin/categoriesReducer';
import productsReducer from './admin/productsReducer';
import priceTypesReducer from './admin/priceTypesReducer';
import productOptionTypesReducer from './admin/productOptionTypesReducer';
import usersReducer from './admin/usersReducer';
import tagsReducer from './admin/tagsReducer';
import cartReducer from './cartReducer';
import errorReducer from './errorReducer';
import homeReducer from './homeReducer';
import mediaReducer from './mediaReducer';
import profileReducer from './profileReducer';
import commonReducer from './commonReducer';
import notificationsReducer from './notificationsReducer';
import feedbackCategoriesReducer from './admin/feedbackCategoriesReducer';

const rootReducer = combineReducers({
   error: errorReducer,
   profile: profileReducer,
   common: commonReducer,
   categories: categoriesReducer,
   products: productsReducer,
   tags: tagsReducer,
   productOptionTypes: productOptionTypesReducer,
   users: usersReducer,
   notifications: notificationsReducer,
   feedbackCategories: feedbackCategoriesReducer,
   priceTypes: priceTypesReducer,
   media: mediaReducer,
   home: homeReducer,
   cart: cartReducer,
});

export default rootReducer;
