import * as actions from '@constants/actions';

const initialState = {
   country: {
      data: null,
      loading: true,
   },
   config: {
      data: null,
      loading: true,
   },
   deviceToken: '',
};

const commonReducer = (state = initialState, action) => {
   switch (action.type) {
      case actions.SET_COUNTRY_DATA:
         return {
            ...state,
            country: {
               data: action.payload,
               loading: false,
            },
         };

      case actions.SET_DEVICE_TOKEN:
         return {
            ...state,
            deviceToken: action.payload,
         };

      case actions.FETCH_CONFIGURATION__PENDING:
         return {
            ...state,
            config: {
               data: null,
               loading: true,
            },
         };
      case actions.FETCH_CONFIGURATION__FULFILLED:
         return {
            ...state,
            config: {
               data: action.payload,
               loading: false,
            },
         };
      case actions.FETCH_CONFIGURATION__REJECTED:
         return {
            ...state,
            config: {
               data: null,
               loading: false,
            },
         };

      default:
         return state;
   }
};
export default commonReducer;
