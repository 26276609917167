import React, { PureComponent } from 'react';

class SkeletonItem extends PureComponent {
   state = {
      rendered: false,
   };
   mounted = false;

   componentWillUnmount() {
      this.mounted = false;
      if (typeof window !== 'undefined') {
         clearTimeout(this.checkTimer);
         window.removeEventListener('scroll', () => this.checkForRender());
      }
   }

   componentDidMount() {
      this.mounted = true;
      if (typeof window !== 'undefined') {
         window.addEventListener('scroll', () => this.checkForRender());
         this.checkForRender();
      }
   }

   componentDidUpdate(prevProps, prevState) {
      if (typeof window !== 'undefined') {
         if (this.state.rendered) {
            window.removeEventListener('scroll', () => this.checkForRender());
         }
      }
   }

   checkForRender = () => {
      clearTimeout(this.checkTimer);
      this.checkTimer = setTimeout(() => {
         if (!this.state.rendered && this.checkItemInViewport(this.itemRef)) {
            if (this.mounted) {
               this.setState({ rendered: true });
            }
         }
      }, 0);
   };

   checkItemInViewport = (el) => {
      if (typeof window !== 'undefined') {
         if (el) {
            const rect = el.getBoundingClientRect();
            return rect.left >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight);
         }
      }
      return true;
   };

   setItemRef = (r) => {
      this.itemRef = r;
   };
   render() {
      if (!this.state.rendered) {
         return (
            <div className="skeleton-item" ref={this.setItemRef}>
               {this.props.shape || null}
            </div>
         );
      }
      return this.props.children || null;
   }
}

export default SkeletonItem;
