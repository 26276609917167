import * as actions from "@constants/actions";
import _take from 'lodash/take';

const initialState = {
   newProducts: {
      content: [],
      loading: false,
   },
   bestSellers: {
      content: [],
      loading: false,
   }
}

const _takeContent = (payload) => {
   const { content } = payload || {};
   if (content?.length) {
      return _take(content, 4);
   }
   return [];
}

const homeReducer = (state = initialState, action) => {
   switch (action.type) {
      case actions.FETCH_HOME_NEW_PRODUCTS__PENDING:
         return {
            ...state,
            newProducts: {
               ...state.newProducts,
               loading: true,
            }
         };
      case actions.FETCH_HOME_NEW_PRODUCTS__FULFILLED:
         return {
            ...state,
            newProducts: {
               loading: false,
               content: _takeContent(action.payload),
            }
         };
      case actions.FETCH_HOME_NEW_PRODUCTS__REJECTED:
         return {
            ...state,
            newProducts: {
               loading: false,
               content: [],
            }
         };

      case actions.FETCH_HOME_BEST_SELLERS__PENDING:
         return {
            ...state,
            bestSellers: {
               ...state.bestSellers,
               loading: true,
            }
         };
      case actions.FETCH_HOME_BEST_SELLERS__FULFILLED:
         return {
            ...state,
            bestSellers: {
               loading: false,
               content: _takeContent(action.payload),
            }
         };
      case actions.FETCH_HOME_BEST_SELLERS__REJECTED:
         return {
            ...state,
            bestSellers: {
               loading: false,
               content: [],
            }
         };
      default:
         return state
   }
}

export default homeReducer;