import React, { PureComponent } from 'react';
import { Spin } from 'antd';

class ContentLoading extends PureComponent {
   state = {
      loading: false,
   };

   componentDidMount() {
      this.setLoading(this.props.isLoading);
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.isLoading !== this.props.isLoading) {
         this.setLoading(this.props.isLoading);
      }
   }

   setLoading = (isLoading) => {
      if (isLoading) {
         clearTimeout(this.timerLoading);
         this.setState({
            isLoading: true,
         });
      } else {
         this.timerLoading = setTimeout(() => {
            this.setState({
               isLoading: false,
            });
         }, 150);
      }
   };

   render() {
      if (this.state.isLoading) {
         return (
            <div className={`content-loading ${this.props.className || ''}`}>
               <Spin className="common-spin" size={this.props.size || 'default'} />
            </div>
         );
      }
      return null;
   }
}

export default ContentLoading;
