module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VgZ Custom","short_name":"Vgz","start_url":"/","background_color":"#f9f9f9","icon":"/Users/kirito/workspace/mine/freelance/vapegangz-custom/vgz-web/src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"70e30291ffc7ce04611597c5d23ad7c8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/kirito/workspace/mine/freelance/vapegangz-custom/vgz-web","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase-messaging/gatsby-browser.js'),
      options: {"plugins":[],"config":{"apiKey":"AIzaSyCwT5uco75V4G2Ajs5IUCYamnx_K3z3ULo","authDomain":"vgz-custom.firebaseapp.com","projectId":"vgz-custom","storageBucket":"vgz-custom.appspot.com","messagingSenderId":"436103637818","appId":"1:436103637818:web:32ca62a18dec5b42987e03","measurementId":"G-8VHR440EYM"},"firebaseVersion":"8.9.0","disableDevelopment":false,"removeFirebaseServiceWorker":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/Users/kirito/workspace/mine/freelance/vapegangz-custom/vgz-web/src/firebase-messaging-sw.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
