import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import LogoFull from '../../../components/Icons/LogoFull';
import { Link } from 'gatsby';
import IconFacebook from '../../../components/Icons/IconFacebook';
import IconInstagram from '../../../components/Icons/IconInstagram';
import IconTwitter from '../../../components/Icons/IconTwitter';
import IconYoutube from '../../../components/Icons/IconYoutube';
import SkeletonItem from '../../../components/Skeleton/SkeletonItem';
import FooterSkeleton from '../../../components/Skeleton/skeleton-components/FooterSkeleton';
import constant from '../../../utils/constant';

const policies = [
   {
      name: 'Privacy Policy',
      linkTo: constant.ROUTE_PRIVACY_POLICY,
   },
   {
      name: 'Shipping Policy',
      linkTo: constant.ROUTE_SHIPPING_POLICY,
   },
   // {
   //    name: 'Refunds',
   //    linkTo: constant.ROUTE_HOME,
   // },
   // {
   //    name: 'Terms and Conditions',
   //    linkTo: constant.ROUTE_HOME,
   // },
];

const supports = [
   // {
   //    name: 'Guideline to buy products',
   //    linkTo: constant.ROUTE_HOME,
   // },
   // {
   //    name: 'Error handling',
   //    linkTo: constant.ROUTE_HOME,
   // },
   // {
   //    name: 'Q&A',
   //    linkTo: constant.ROUTE_HOME,
   // },
   {
      name: 'Feedback',
      linkTo: constant.ROUTE_FEEDBACK,
   },
];

const socials = [
   {
      name: 'Facebook',
      linkTo: constant.SOCIAL_FACEBOOK,
      icon: IconFacebook,
   },
   {
      name: 'Instagram',
      linkTo: constant.SOCIAL_INSTAGRAM,
      icon: IconInstagram,
   },
   // {
   //    name: 'Twitter',
   //    linkTo: constant.ROUTE_HOME,
   //    icon: IconTwitter,
   // },
   // {
   //    name: 'Youtube',
   //    linkTo: constant.ROUTE_HOME,
   //    icon: IconYoutube,
   // },
];

class Footer extends PureComponent {
   render() {
      return (
         <SkeletonItem shape={<FooterSkeleton />}>
            <div className="footer">
               <Row gutter={30}>
                  <Col lg={6} md={6} sm={24} xs={24}>
                     <div className="footer-section">
                        <Link
                           className="lofo-full animate__animated animate__fadeInUp"
                           to={constant.ROUTE_HOME}
                        >
                           <LogoFull />
                        </Link>
                     </div>
                  </Col>
                  <Col lg={6} md={6} sm={24} xs={24}>
                     <div className="footer-section animate__animated animate__fadeInUp">
                        <label className="section-label ">POLICIES</label>
                        {policies.map((item) => (
                           <Link key={item.name} to={item.linkTo} className="footer-link">
                              {item.name}
                           </Link>
                        ))}
                     </div>
                  </Col>
                  <Col lg={6} md={6} sm={24} xs={24}>
                     <div className="footer-section animate__animated animate__fadeInUp">
                        <label className="section-label">SUPPORT</label>
                        {supports.map((item) => (
                           <Link key={item.name} to={item.linkTo} className="footer-link">
                              {item.name}
                           </Link>
                        ))}
                     </div>
                  </Col>
                  <Col lg={6} md={6} sm={24} xs={24}>
                     <div className="footer-section animate__animated animate__fadeInUp">
                        <label className="section-label">FOLLOW US</label>
                        {socials.map((item) => {
                           let Icon = item.icon;
                           return (
                              <Link
                                 key={item.name}
                                 to={item.linkTo}
                                 target="_blank"
                                 className="footer-link"
                              >
                                 {Icon ? <Icon /> : null}
                                 <span>{item.name}</span>
                              </Link>
                           );
                        })}
                     </div>
                  </Col>
               </Row>
            </div>
         </SkeletonItem>
      );
   }
}

export default Footer;
