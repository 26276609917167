/*eslint no-undef: 0*/

import moment from 'moment-timezone';
import constant from './constant';
import _deburr from 'lodash/deburr';
import _replace from 'lodash/replace';
import _toNumber from 'lodash/toNumber';
import { FileSize } from './enums/FileSize';
import exchangeRate from './exchange_rate.json';

export const getTimeZone = () => {
   return moment.tz.guess();
};

export const convertToDateTime = (ISOString, dateTimeFormat) => {
   const stillUtc = moment(ISOString).utc().toDate();
   return moment(stillUtc)
      .tz(getTimeZone())
      .format(dateTimeFormat || constant.dateTimeFormat);
};
export const convertToDate = (ISOString, dateFormat) => {
   const stillUtc = moment(ISOString).utc().toDate();
   return moment(stillUtc)
      .tz(getTimeZone())
      .format(dateFormat || constant.dateFormat);
};
export const convertToTime = (ISOString, timeFormat) => {
   const stillUtc = moment(ISOString).utc().toDate();
   return moment(stillUtc)
      .tz(getTimeZone())
      .format(timeFormat || constant.timeFormat);
};

export const getNow = (format) => {
   return moment()
      .tz(getTimeZone())
      .format(format || constant.dateValueFormat);
};

export const parseDateTimeStringToDateValueFormat = (dateValue, format) => {
   if (dateValue) {
      return moment(dateValue)
         .tz(getTimeZone())
         .format(format || constant.dateValueFormat);
   }
   return '';
};

export const currentToUtc0 = (fromValue, fromFormat, toFormat) => {
   if (fromValue) {
      return moment(fromValue, fromFormat)
         .tz(getTimeZone())
         .utc()
         .format(toFormat || constant.datetimeValueFormat);
   }
   return '';
};

export const utcToCurrent = (fromValue, fromFormat, toFormat) => {
   if (fromValue) {
      return moment(fromValue, fromFormat && fromFormat)
         .tz(getTimeZone())
         .format(toFormat || constant.datetimeValueFormat);
   }
   return '';
};

export const generateId = () => {
   return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      // eslint-disable-next-line no-mixed-operators
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
   );
};

export const isValidURL = (string) => {
   let pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
   return pattern.test(string);
};

export const generateRandomNumber = (length) => {
   return (
      Math.pow(10, length)
         .toString()
         .slice(length - 1) +
      Math.floor(Math.random() * Math.pow(10, length) + 1).toString()
   ).slice(-length);
};

export const generateRandomString = (length) => {
   const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
   return Array.apply(0, Array(length))
      .map(() => charset.charAt(Math.floor(Math.random() * charset.length)))
      .join('');
};

export const escapesValue = (value) => {
   let newValue = _deburr(`${value}`.toLowerCase());
   if (newValue && newValue !== '') {
      let map = {
         ' ': ' ',
         _: '_',
         '-': '_',
         á: 'a',
         à: 'a',
         ả: 'a',
         ã: 'a',
         ạ: 'a',
         ă: 'a',
         ắ: 'a',
         ằ: 'a',
         ẳ: 'a',
         ẵ: 'a',
         ặ: 'a',
         â: 'a',
         ấ: 'a',
         ầ: 'a',
         ẩ: 'a',
         ẫ: 'a',
         ậ: 'a',
         é: 'e',
         è: 'e',
         ẻ: 'e',
         ẽ: 'e',
         ẹ: 'e',
         ê: 'e',
         ế: 'e',
         ề: 'e',
         ể: 'e',
         ễ: 'e',
         ệ: 'e',
         đ: 'd',
         í: 'i',
         ì: 'i',
         ỉ: 'i',
         ĩ: 'i',
         ị: 'i',
         ó: 'o',
         ò: 'o',
         ỏ: 'o',
         õ: 'o',
         ọ: 'o',
         ô: 'o',
         ố: 'o',
         ồ: 'o',
         ổ: 'o',
         ỗ: 'o',
         ộ: 'o',
         ơ: 'o',
         ớ: 'o',
         ờ: 'o',
         ở: 'o',
         ỡ: 'o',
         ợ: 'o',
         ú: 'u',
         ù: 'u',
         ủ: 'u',
         ũ: 'u',
         ụ: 'u',
         ư: 'u',
         ứ: 'u',
         ừ: 'u',
         ử: 'u',
         ữ: 'u',
         ự: 'u',
         ý: 'y',
         ỳ: 'y',
         ỷ: 'y',
         ỹ: 'y',
         ỵ: 'y',
      };
      [...newValue].map((c) => {
         if (map[c] !== void 0) {
            newValue = _replace(newValue, c, map[c]);
         } else {
            const letters = /^[0-9a-zA-Z]+$/;
            if (!c.match(letters)) {
               newValue = _replace(newValue, c, '');
            }
         }
         return c;
      });
   }
   return newValue;
};

// const exchangeR

export const kmpSearch = (pattern, text) => {
   if (pattern.length === 0) return 0;

   let lsp = [0]; // Base case
   for (let i = 1; i < pattern.length; i++) {
      let j = lsp[i - 1]; // Start by assuming we're extending the previous LSP
      while (j > 0 && pattern.charAt(i) !== pattern.charAt(j)) j = lsp[j - 1];
      if (pattern.charAt(i) === pattern.charAt(j)) j++;
      lsp.push(j);
   }

   let j = 0;
   for (let i = 0; i < text.length; i++) {
      while (j > 0 && text.charAt(i) !== pattern.charAt(j)) j = lsp[j - 1];
      if (text.charAt(i) === pattern.charAt(j)) {
         j++;
         if (j === pattern.length) return i - (j - 1);
      }
   }
   return -1; // Not found
};

export const sortByDate = (sortKey) => (obj1, obj2) => {
   if (obj1?.[sortKey] && obj2?.[sortKey]) {
      const date1 = new Date(obj1[sortKey]).getTime();
      const date2 = new Date(obj2[sortKey]).getTime();
      return date2 > date1 ? 1 : -1;
   }
   return -1;
};

export const checkIsAttachment = (item) => {
   if (
      item &&
      item?.url &&
      item?.size &&
      item?.formats &&
      item?.content_type &&
      item?.formats?.[FileSize.THUMBNAIL]
   ) {
      return true;
   }
   return false;
};

export const exchangeCurrency = (number, from, to) => {
   const fromRate = exchangeRate.rates[from];
   const toRate = exchangeRate.rates[to];
   return ((toRate / fromRate) * _toNumber(number)).toFixed(2);
};

export const getPrevDigitsCard = (brand) => {
   if (brand === 'amex') {
      return '●●●● ●●●●●● ●';
   }
   return '●●●● ●●●● ●●●● ';
};

export const getDisplayAddress = (data) => {
   if (data?.country) {
      let addrStr = data?.street_address || '';
      if (data.city) {
         addrStr = `${addrStr}${addrStr ? ', ' : ''}${data.city}`;
      }
      if (data.province) {
         addrStr = `${addrStr}${addrStr ? ', ' : ''}${data.province}`;
      }
      if (data.country?.name) {
         addrStr = `${addrStr}${addrStr ? ', ' : ''}${data.country.name}`;
      }
      return addrStr;
   }
   return '';
};

export const calculateTimeLeft = (time) => {
   const now = new Date();
   const timeLeft = Math.abs(now - new Date(time));
   const seconds = Math.floor((timeLeft / 1000) % 60);
   const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
   const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
   const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
   return {
      days,
      hours,
      minutes,
      seconds,
   };
};

export const getDisplayTimeLeft = (ISOString) => {
   if (!ISOString) return '';
   const { days, hours, minutes, seconds } = calculateTimeLeft(ISOString);
   const getPluralSuffix = (v) => {
      if (v > 1) {
         return 's';
      }
      return '';
   };
   if (days > 28) {
      return convertToDate(ISOString);
   }
   if (days > 0) {
      return `${days} day${getPluralSuffix(days)} ago`;
   }
   if (hours > 0) {
      return `${hours} hour${getPluralSuffix(hours)} ago`;
   }
   if (minutes > 0) {
      return `${minutes} minute${getPluralSuffix(minutes)} ago`;
   }
   return `${seconds} second${getPluralSuffix(seconds)} ago`;
};
