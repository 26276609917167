import { dispatch } from '@store/store';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SET_ERROR } from '../../../constants/actions';
import Error from './Error';
import { getErrorCodeSelector } from '@utils/selectors';

const ErrorDetector = memo(({ onError }) => {
   const errorCode = useSelector(getErrorCodeSelector);
   useEffect(() => {
      if (errorCode) {
         onError(errorCode);
      }
   }, [errorCode, onError]);

   return null;
});

const setError = (errorCode) => {
   if (Object.values(Error).includes(errorCode)) {
      setTimeout(() => {
         dispatch({
            type: SET_ERROR,
            errorCode: errorCode,
         });
      }, 100);
   }
};

export { ErrorDetector, setError };
