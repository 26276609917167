import React, { PureComponent } from 'react';

class IconCart extends PureComponent {
   render() {
      return (
         <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <rect
               x="2.75"
               y="1.75"
               width="18.5"
               height="20.5"
               rx="3.25"
               stroke="currentColor"
               strokeWidth="1.5"
            />
            <path
               d="M16 6.375C16 8.58414 14.2091 10.375 12 10.375C9.79086 10.375 8 8.58414 8 6.375"
               stroke="currentColor"
               strokeWidth="1.5"
               strokeMiterlimit={10}
               strokeLinecap="round"
               strokeLinejoin="round"
            />
         </svg>
      );
   }
}

export default IconCart;