import React from 'react';

const IconMenu2 = () => (
   <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         className="path1"
         d="M3 18H21"
         stroke="currentColor"
         strokeWidth="1.5"
         strokeMiterlimit={10}
         strokeLinecap="round"
      />
      <path
         className="path2"
         d="M3 5.99997H21"
         stroke="currentColor"
         strokeWidth="1.5"
         strokeMiterlimit={10}
         strokeLinecap="round"
      />
   </svg>
);

export default IconMenu2;
