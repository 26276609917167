const SET_ERROR = 'SET_ERROR';

// get me
const GET_ME = 'GET_ME';
const GET_ME__PENDING = 'GET_ME__PENDING';
const GET_ME__FULFILLED = 'GET_ME__FULFILLED';
const GET_ME__REJECTED = 'GET_ME__REJECTED';

// common
const SET_COUNTRY_DATA = 'SET_COUNTRY_DATA';
const SET_DEVICE_TOKEN = 'SET_DEVICE_TOKEN';

// fetch categories
const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
const FETCH_CATEGORIES__PENDING = 'FETCH_CATEGORIES__PENDING';
const FETCH_CATEGORIES__FULFILLED = 'FETCH_CATEGORIES__FULFILLED';
const FETCH_CATEGORIES__REJECTED = 'FETCH_CATEGORIES__REJECTED';

// fetch products
const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
const FETCH_PRODUCTS__PENDING = 'FETCH_PRODUCTS__PENDING';
const FETCH_PRODUCTS__FULFILLED = 'FETCH_PRODUCTS__FULFILLED';
const FETCH_PRODUCTS__REJECTED = 'FETCH_PRODUCTS__REJECTED';

// fetch tags
const FETCH_TAGS = 'FETCH_TAGS';
const FETCH_TAGS__PENDING = 'FETCH_TAGS__PENDING';
const FETCH_TAGS__FULFILLED = 'FETCH_TAGS__FULFILLED';
const FETCH_TAGS__REJECTED = 'FETCH_TAGS__REJECTED';

// fetch product option types
const FETCH_PRO_OPTION_TYPES = 'FETCH_PRO_OPTION_TYPES';
const FETCH_PRO_OPTION_TYPES__PENDING = 'FETCH_PRO_OPTION_TYPES__PENDING';
const FETCH_PRO_OPTION_TYPES__FULFILLED = 'FETCH_PRO_OPTION_TYPES__FULFILLED';
const FETCH_PRO_OPTION_TYPES__REJECTED = 'FETCH_PRO_OPTION_TYPES__REJECTED';

// fetch price types
const FETCH_PRICE_TYPES = 'FETCH_PRICE_TYPES';
const FETCH_PRICE_TYPES__PENDING = 'FETCH_PRICE_TYPES__PENDING';
const FETCH_PRICE_TYPES__FULFILLED = 'FETCH_PRICE_TYPES__FULFILLED';
const FETCH_PRICE_TYPES__REJECTED = 'FETCH_PRICE_TYPES__REJECTED';

// media
const SAVE_TO_MEDIA = 'SAVE_TO_MEDIA';
const DELETE_FROM_MEDIA = 'DELETE_FROM_MEDIA';

// home
const FETCH_HOME_NEW_PRODUCTS = 'FETCH_HOME_NEW_PRODUCTS';
const FETCH_HOME_NEW_PRODUCTS__PENDING = 'FETCH_HOME_NEW_PRODUCTS__PENDING';
const FETCH_HOME_NEW_PRODUCTS__FULFILLED = 'FETCH_HOME_NEW_PRODUCTS__FULFILLED';
const FETCH_HOME_NEW_PRODUCTS__REJECTED = 'FETCH_HOME_NEW_PRODUCTS__REJECTED';
const FETCH_HOME_BEST_SELLERS = 'FETCH_HOME_BEST_SELLERS';
const FETCH_HOME_BEST_SELLERS__PENDING = 'FETCH_HOME_BEST_SELLERS__PENDING';
const FETCH_HOME_BEST_SELLERS__FULFILLED = 'FETCH_HOME_BEST_SELLERS__FULFILLED';
const FETCH_HOME_BEST_SELLERS__REJECTED = 'FETCH_HOME_BEST_SELLERS__REJECTED';

// cart
const FETCH_CART = 'FETCH_CART';
const FETCH_CART__PENDING = 'FETCH_CART__PENDING';
const FETCH_CART__FULFILLED = 'FETCH_CART__FULFILLED';
const FETCH_CART__REJECTED = 'FETCH_CART__REJECTED';
const ADD_TO_CART = 'ADD_TO_CART';
const ADD_TO_CART__PENDING = 'ADD_TO_CART__PENDING';
const ADD_TO_CART__FULFILLED = 'ADD_TO_CART__FULFILLED';
const ADD_TO_CART__REJECTED = 'ADD_TO_CART__REJECTED';
const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
const UPDATE_CART_ITEM__PENDING = 'UPDATE_CART_ITEM__PENDING';
const UPDATE_CART_ITEM__FULFILLED = 'UPDATE_CART_ITEM__FULFILLED';
const UPDATE_CART_ITEM__REJECTED = 'UPDATE_CART_ITEM__REJECTED';
const DELETE_CART_ITEM = 'DELETE_CART_ITEM';
const DELETE_CART_ITEM__PENDING = 'DELETE_CART_ITEM__PENDING';
const DELETE_CART_ITEM__FULFILLED = 'DELETE_CART_ITEM__FULFILLED';
const DELETE_CART_ITEM__REJECTED = 'DELETE_CART_ITEM__REJECTED';
const CLEAR_CART_ERROR = 'CLEAR_CART_ERROR';

// local cart
const PUSH_ITEM_TO_LOCAL_CART = 'PUSH_ITEM_TO_LOCAL_CART';
const REMOVE_ITEM_FROM_LOCAL_CART = 'REMOVE_ITEM_FROM_LOCAL_CART';
const UPDATE_ITEM_IN_LOCAL_CART = 'UPDATE_ITEM_IN_LOCAL_CART';
const CLEAR_LOCAL_CART = 'CLEAR_LOCAL_CART';

// fetch users
const FETCH_USERS = 'FETCH_USERS';
const FETCH_USERS__PENDING = 'FETCH_USERS__PENDING';
const FETCH_USERS__FULFILLED = 'FETCH_USERS__FULFILLED';
const FETCH_USERS__REJECTED = 'FETCH_USERS__REJECTED';

// fetch user notifications
const FETCH_USER_NOTIFICATIONS = 'FETCH_USER_NOTIFICATIONS';
const FETCH_USER_NOTIFICATIONS__PENDING = 'FETCH_USER_NOTIFICATIONS__PENDING';
const FETCH_USER_NOTIFICATIONS__FULFILLED = 'FETCH_USER_NOTIFICATIONS__FULFILLED';
const FETCH_USER_NOTIFICATIONS__REJECTED = 'FETCH_USER_NOTIFICATIONS__REJECTED';
const FETCH_USER_NOTIFICATIONS_AMOUNT = 'FETCH_USER_NOTIFICATIONS_AMOUNT';
const FETCH_USER_NOTIFICATIONS_AMOUNT__PENDING =
   'FETCH_USER_NOTIFICATIONS_AMOUNT__PENDING';
const FETCH_USER_NOTIFICATIONS_AMOUNT__FULFILLED =
   'FETCH_USER_NOTIFICATIONS_AMOUNT__FULFILLED';
const FETCH_USER_NOTIFICATIONS_AMOUNT__REJECTED =
   'FETCH_USER_NOTIFICATIONS_AMOUNT__REJECTED';
const FETCH_POPUP_USER_NOTIFICATIONS = 'FETCH_POPUP_USER_NOTIFICATIONS';
const FETCH_POPUP_USER_NOTIFICATIONS__FULFILLED =
   'FETCH_POPUP_USER_NOTIFICATIONS__FULFILLED';

// fetch feedback categories
const FETCH_FEEDBACK_CATEGORIES = 'FETCH_FEEDBACK_CATEGORIES';
const FETCH_FEEDBACK_CATEGORIES__PENDING = 'FETCH_FEEDBACK_CATEGORIES__PENDING';
const FETCH_FEEDBACK_CATEGORIES__FULFILLED = 'FETCH_FEEDBACK_CATEGORIES__FULFILLED';
const FETCH_FEEDBACK_CATEGORIES__REJECTED = 'FETCH_FEEDBACK_CATEGORIES__REJECTED';

// config
const FETCH_CONFIGURATION = 'FETCH_CONFIGURATION';
const FETCH_CONFIGURATION__PENDING = 'FETCH_CONFIGURATION__PENDING';
const FETCH_CONFIGURATION__FULFILLED = 'FETCH_CONFIGURATION__FULFILLED';
const FETCH_CONFIGURATION__REJECTED = 'FETCH_CONFIGURATION__REJECTED';

export {
   GET_ME,
   GET_ME__PENDING,
   GET_ME__FULFILLED,
   GET_ME__REJECTED,
   SET_COUNTRY_DATA,
   SET_DEVICE_TOKEN,
   SET_ERROR,
   FETCH_CATEGORIES,
   FETCH_CATEGORIES__PENDING,
   FETCH_CATEGORIES__FULFILLED,
   FETCH_CATEGORIES__REJECTED,
   FETCH_PRODUCTS,
   FETCH_PRODUCTS__PENDING,
   FETCH_PRODUCTS__FULFILLED,
   FETCH_PRODUCTS__REJECTED,
   FETCH_TAGS,
   FETCH_TAGS__PENDING,
   FETCH_TAGS__FULFILLED,
   FETCH_TAGS__REJECTED,
   FETCH_PRO_OPTION_TYPES,
   FETCH_PRO_OPTION_TYPES__PENDING,
   FETCH_PRO_OPTION_TYPES__FULFILLED,
   FETCH_PRO_OPTION_TYPES__REJECTED,
   FETCH_PRICE_TYPES,
   FETCH_PRICE_TYPES__PENDING,
   FETCH_PRICE_TYPES__FULFILLED,
   FETCH_PRICE_TYPES__REJECTED,
   SAVE_TO_MEDIA,
   DELETE_FROM_MEDIA,
   FETCH_HOME_NEW_PRODUCTS,
   FETCH_HOME_NEW_PRODUCTS__PENDING,
   FETCH_HOME_NEW_PRODUCTS__FULFILLED,
   FETCH_HOME_NEW_PRODUCTS__REJECTED,
   FETCH_HOME_BEST_SELLERS,
   FETCH_HOME_BEST_SELLERS__PENDING,
   FETCH_HOME_BEST_SELLERS__FULFILLED,
   FETCH_HOME_BEST_SELLERS__REJECTED,
   FETCH_CART,
   FETCH_CART__PENDING,
   FETCH_CART__FULFILLED,
   FETCH_CART__REJECTED,
   ADD_TO_CART,
   ADD_TO_CART__PENDING,
   ADD_TO_CART__FULFILLED,
   ADD_TO_CART__REJECTED,
   UPDATE_CART_ITEM,
   UPDATE_CART_ITEM__PENDING,
   UPDATE_CART_ITEM__FULFILLED,
   UPDATE_CART_ITEM__REJECTED,
   DELETE_CART_ITEM,
   DELETE_CART_ITEM__PENDING,
   DELETE_CART_ITEM__FULFILLED,
   DELETE_CART_ITEM__REJECTED,
   CLEAR_CART_ERROR,
   PUSH_ITEM_TO_LOCAL_CART,
   REMOVE_ITEM_FROM_LOCAL_CART,
   UPDATE_ITEM_IN_LOCAL_CART,
   CLEAR_LOCAL_CART,
   FETCH_USERS,
   FETCH_USERS__PENDING,
   FETCH_USERS__FULFILLED,
   FETCH_USERS__REJECTED,
   FETCH_USER_NOTIFICATIONS,
   FETCH_USER_NOTIFICATIONS__PENDING,
   FETCH_USER_NOTIFICATIONS__FULFILLED,
   FETCH_USER_NOTIFICATIONS__REJECTED,
   FETCH_USER_NOTIFICATIONS_AMOUNT,
   FETCH_USER_NOTIFICATIONS_AMOUNT__PENDING,
   FETCH_USER_NOTIFICATIONS_AMOUNT__FULFILLED,
   FETCH_USER_NOTIFICATIONS_AMOUNT__REJECTED,
   FETCH_POPUP_USER_NOTIFICATIONS,
   FETCH_POPUP_USER_NOTIFICATIONS__FULFILLED,
   FETCH_FEEDBACK_CATEGORIES,
   FETCH_FEEDBACK_CATEGORIES__PENDING,
   FETCH_FEEDBACK_CATEGORIES__FULFILLED,
   FETCH_FEEDBACK_CATEGORIES__REJECTED,
   FETCH_CONFIGURATION,
   FETCH_CONFIGURATION__PENDING,
   FETCH_CONFIGURATION__FULFILLED,
   FETCH_CONFIGURATION__REJECTED,
};
