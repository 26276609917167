import * as actions from '@constants/actions';

const initialState = {
   data: null,
   loading: false,
};

const getProfileData = (payload) => {
   if (payload) {
      const data = { ...payload };
      let displayName = '';
      if (payload?.full_name) {
         displayName = payload?.full_name;
      } else if (payload?.first_name && payload?.last_name) {
         displayName = `${payload?.first_name} ${payload?.last_name}`;
      } else if (payload?.email) {
         displayName = payload?.email;
      }
      if (!displayName) {
         displayName = payload?.username || '';
      }
      data.displayName = displayName;
      return data;
   }
   return payload;
};

const profileReducer = (state = initialState, action) => {
   switch (action.type) {
      case actions.GET_ME__PENDING:
         return {
            ...state,
            loading: true,
         };
      case actions.GET_ME__FULFILLED:
         return {
            ...state,
            data: getProfileData(action.payload),
            loading: false,
         };
      case actions.GET_ME__REJECTED:
         return {
            ...state,
            data: null,
            loading: false,
         };
      default:
         return state;
   }
};

export default profileReducer;
