
import React, { PureComponent } from 'react';

class IconUser extends PureComponent {
   render() {
      return (
         <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M8 8C9.933 8 11.5 6.433 11.5 4.5C11.5 2.567 9.933 1 8 1C6.067 1 4.5 2.567 4.5 4.5C4.5 6.433 6.067 8 8 8Z"
               stroke="black"
               strokeLinecap="round"
               strokeLinejoin="round"
            />
            <path
               d="M14 15C14 12.2357 11.3108 10 8 10C4.68917 10 2 12.2357 2 15"
               stroke="black"
               strokeLinecap="round"
               strokeLinejoin="round"
            />
         </svg>
      );
   }
}

export default IconUser;