import { createSelector } from 'reselect';

const selectErrorCode = (state) => state.error;

// error code
export const getErrorCodeSelector = createSelector(
   selectErrorCode,
   (errorStates) => errorStates.errorCode
);

// profile
export const getProfileSelector = createSelector(
   (state) => state.profile,
   (profile) => profile
);

const _extractFromDataObj = (dataObj) => {
   let data = { ...dataObj };
   if (data.content) {
      data.content = Object.values(dataObj.content);
   }
   return data;
};

const dataSelector = (stateSelector) => createSelector(stateSelector, (s) => s.data);
const loadingSelector = (stateSelector) =>
   createSelector(stateSelector, (s) => s.loading);
const errorSelector = (stateSelector) => createSelector(stateSelector, (s) => s.error);

// product options
const _selectProductOptionTypes = (state) => state.productOptionTypes;
export const getProductOptionTypesSelector = createSelector(
   dataSelector(_selectProductOptionTypes),
   loadingSelector(_selectProductOptionTypes),
   (data, loading) => ({ data: _extractFromDataObj(data), loading })
);

// price type
const _selectPriceTypes = (state) => state.priceTypes;
export const getPriceTypesSelector = createSelector(
   dataSelector(_selectPriceTypes),
   loadingSelector(_selectPriceTypes),
   (data, loading) => ({ data: _extractFromDataObj(data), loading })
);

// media
const _selectMedia = (state) => state.media;
export const getMediaItemSelector = (mediaUid) =>
   createSelector(dataSelector(_selectMedia), (data) => data[mediaUid] || null);

// tags
const _selectTags = (state) => state.tags;
export const getTagsSelector = createSelector(
   dataSelector(_selectTags),
   loadingSelector(_selectTags),
   (data, loading) => ({ data: _extractFromDataObj(data), loading })
);

// categories
const _selectCategories = (state) => state.categories;
export const getCategoriesSelector = createSelector(
   dataSelector(_selectCategories),
   loadingSelector(_selectCategories),
   (data, loading) => ({ data: _extractFromDataObj(data), loading })
);

// products
const _selectProducts = (state) => state.products;
export const getProductsSelector = createSelector(
   dataSelector(_selectProducts),
   loadingSelector(_selectProducts),
   (data, loading) => ({ data: _extractFromDataObj(data), loading })
);

// users
const _selectUsers = (state) => state.users;
export const getUsersSelector = createSelector(
   dataSelector(_selectUsers),
   loadingSelector(_selectUsers),
   (data, loading) => ({ data: _extractFromDataObj(data), loading })
);

// cart
const _selectCart = (state) => state.cart;
const cartDataSelector = createSelector(dataSelector(_selectCart), (data) => ({
   cart: data.cart,
   items: data.items,
}));
export const getCartSelector = createSelector(
   cartDataSelector,
   loadingSelector(_selectCart),
   errorSelector(_selectCart),
   ({ cart, items }, loading, error) => ({
      cart,
      items: Object.values(items),
      loading,
      error,
   })
);
export const getCartItemsNumberSelector = createSelector(
   cartDataSelector,
   ({ items }) => Object.values(items).length
);

const _selectLocalCart = createSelector(_selectCart, (cartState) => cartState.localCart);
export const getLocalCartSelector = createSelector(_selectLocalCart, (localCart) =>
   Object.values(localCart)
);
export const getLocalCartItemsNumberSelector = createSelector(
   _selectLocalCart,
   (localCart) => Object.values(localCart).length
);

// noti
const _selectNotifications = (state) => state.notifications;
export const getPopupNotificationsSelector = createSelector(
   createSelector(_selectNotifications, (s) => s.popupData),
   loadingSelector(_selectNotifications),
   (popupData, loading) => ({ data: popupData, loading })
);
export const getPopupNotificationsCountSelector = createSelector(
   createSelector(_selectNotifications, (s) => s.popupData),
   (popupData) => popupData.amount
);
export const getNotificationsSelector = createSelector(
   dataSelector(_selectNotifications),
   loadingSelector(_selectNotifications),
   (data, loading) => ({ data, loading })
);

// feedback categories
const _selectFeedbackCategories = (state) => state.feedbackCategories;
export const getFeedbackCategoriesSelector = createSelector(
   dataSelector(_selectFeedbackCategories),
   loadingSelector(_selectFeedbackCategories),
   (data, loading) => ({ data: _extractFromDataObj(data), loading })
);
