import React, { memo, useCallback, useEffect, useState } from 'react';
import IconCart from '../../../components/Icons/IconCart';
import { Menu, Dropdown } from 'antd';
import { Link } from 'gatsby';
import constant from '../../../utils/constant';
import IconFavorite from '../../../components/Icons/IconFavorite';
import IconCart16 from '../../../components/Icons/IconCart16';
import IconLogout from '../../../components/Icons/IconLogout';
import IconUser from '../../../components/Icons/IconUser';
import { useSelector } from 'react-redux';
import { profileApi } from '../../../apis/profileApi';
import { profileService } from '../../../services/profileService';
import { tokenUtil } from '../../../utils/token';
import { message } from '../../../components/Common/Message';
import { Role } from '@utils/enums/Role';
import IconAdmin from '@icons/IconAdmin';
import {
   getCartItemsNumberSelector,
   getLocalCartItemsNumberSelector,
   getPopupNotificationsCountSelector,
   getProfileSelector,
} from '@utils/selectors';
import Breakpoint from '@components/Breakpoint/Breakpoint';
import IconCancel from '@icons/IconCancel';
import MbBackdrop from './MbBackdrop';
import IconNotification from '@icons/IconNotification';

const CartCount = memo(() => {
   const { data: userProfile } = useSelector(getProfileSelector);
   const cartItemsNumber = useSelector(getCartItemsNumberSelector);
   const localCartItemsNumber = useSelector(getLocalCartItemsNumberSelector);
   const [visible, setVisible] = useState(false);

   const [itemsNumberInCart, setItemsNumberInCart] = useState(0);

   useEffect(() => {
      if (userProfile) {
         setItemsNumberInCart(cartItemsNumber);
      } else {
         setItemsNumberInCart(localCartItemsNumber);
      }
   }, [userProfile, cartItemsNumber, localCartItemsNumber]);

   const logout = async () => {
      setVisible(false);
      const responseData = await profileApi.logout();
      if (responseData.isSuccess) {
         profileService.removeUserProfile();
         message.info('Logged out!');
         tokenUtil.clearUserData();
      } else {
         message.error(responseData.message);
      }
   };

   const renderMenu = useCallback(() => {
      console.log(userProfile);
      return (
         <Menu className="common-menu profile-menu">
            <Breakpoint md down>
               <Menu.Item className="mb-menu-profile-close" key="close">
                  <i onClick={() => setVisible(false)}>
                     <IconCancel />
                  </i>
               </Menu.Item>
            </Breakpoint>
            <Menu.Item
               className="common-menu-item"
               key="1"
               onClick={() => setVisible(false)}
            >
               <Link to={`${constant.ROUTE_CART}`}>
                  <IconCart16 />
                  <span>
                     Cart{' '}
                     {itemsNumberInCart > 0
                        ? `(${itemsNumberInCart > 99 ? '99+' : itemsNumberInCart})`
                        : ''}
                  </span>
               </Link>
            </Menu.Item>

            {userProfile ? (
               <>
                  <Menu.Item className="common-menu-item" key="7">
                     <MenuNotification setVisible={setVisible} />
                  </Menu.Item>
                  <Menu.Item className="common-menu-item" key="2">
                     <Link
                        to={`${constant.ROUTE_PROFILE_WISHLIST}`}
                        onClick={() => setVisible(false)}
                     >
                        <IconFavorite />
                        <span>Wishlist</span>
                     </Link>
                  </Menu.Item>
                  <Menu.Item className="common-menu-item" key="3">
                     <Link
                        to={`${constant.ROUTE_PROFILE_INFO}`}
                        onClick={() => setVisible(false)}
                     >
                        {userProfile.avatar_url ? (
                           <div
                              className="avatar"
                              style={{
                                 backgroundImage: `url(${userProfile.avatar_url})`,
                              }}
                           />
                        ) : (
                           <IconUser />
                        )}
                        <span>Profile</span>
                     </Link>
                  </Menu.Item>
                  {(userProfile?.role_name === Role.MANAGER ||
                     userProfile?.role_name === Role.SYSTEM) && (
                     <Menu.Item
                        className="common-menu-item"
                        key="4"
                        onClick={() => setVisible(false)}
                     >
                        <Link to={`${constant.ROUTE_ADMIN_PRODUCTS}`}>
                           <IconAdmin />
                           <span>Admin page</span>
                        </Link>
                     </Menu.Item>
                  )}
                  <Menu.Item className="common-menu-item" onClick={logout} key="5">
                     <IconLogout />
                     <span>Logout</span>
                  </Menu.Item>
               </>
            ) : (
               <Menu.Item
                  className="common-menu-item"
                  key="6"
                  onClick={() => setVisible(false)}
               >
                  <Link to={`${constant.ROUTE_LOGIN}`}>
                     <IconUser />
                     <span>Sign In / Sign Up</span>
                  </Link>
               </Menu.Item>
            )}
         </Menu>
      );
   }, [userProfile, itemsNumberInCart]);

   return (
      <>
         <Dropdown
            overlayClassName="common-dropdown header-profile-dropdown"
            overlay={renderMenu()}
            placement="bottomRight"
            trigger="click"
            visible={visible}
            onVisibleChange={(v) => setVisible(v)}
         >
            <div className="menu-item">
               <div className="cart-count">
                  <IconCart />
                  {itemsNumberInCart !== 0 && (
                     <span className="count">
                        {itemsNumberInCart > 99 ? '99+' : itemsNumberInCart}
                     </span>
                  )}
               </div>
            </div>
         </Dropdown>
         <MbBackdrop visible={visible} onClick={() => setVisible(false)} />
      </>
   );
});

const MenuNotification = memo(({ setVisible }) => {
   const amount = useSelector(getPopupNotificationsCountSelector);
   const { unread = 0 } = amount || {};

   return (
      <Link
         to={`${constant.ROUTE_PROFILE_NOTIFICATIONS}`}
         onClick={() => setVisible(false)}
      >
         <IconNotification />
         <span>
            Notifications {unread > 0 ? `(${unread > 99 ? '99+' : unread})` : ''}
         </span>
      </Link>
   );
});

export default CartCount;
