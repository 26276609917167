import React, { memo, useEffect } from 'react';
import Header from '@views/default-layout/header/Header';
import Footer from '@views/default-layout/footer/Footer';
import { functionUtils } from '@utils/function';
import IconPageNotFound from '@icons/IconPageNotFound';
import SEO from '@components/SEO';
import IconForbidden from '@icons/IconForbidden';

const Page403 = memo(() => {
   useEffect(() => {
      functionUtils.setModalOpen(true);
      return () => {
         functionUtils.setModalOpen(false);
      };
   }, []);

   return (
      <div className="vgz-error-page">
         <SEO title="Forbidden" />
         <Header />
         <main className="main">
            <div className="layout-wrapper">
               <div className="page-error">
                  <div className="content">
                     <IconForbidden />
                     <div className="msg">
                        <label>403</label>
                        <span>Forbidden</span>
                     </div>
                  </div>
               </div>
               <Footer />
            </div>
         </main>
      </div>
   );
});

export default Page403;
