import { FETCH_CONFIGURATION } from '../constants/actions';
import { dispatch } from '@store/store';
import Request from './utils/Request';
import { apiConstant } from '@apis/constant';
import { tokenUtil } from '@utils/token';
import { fetch } from '@apis/ServiceController';
import { adminService } from './adminService';

const getConfigurationDetails = async (cancelToken) => {
   return fetch({
      method: 'get',
      url: apiConstant.GET_CONFIGURATION_DETAILS,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
   });
};

const updateConfiguration = async (body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_CONFIGURATION,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const loadConfiguration = async () => {
   return await dispatch(Request.get(FETCH_CONFIGURATION));
};

export const configurationService = {
   getConfigurationDetails,
   updateConfiguration,
   loadConfiguration,
};
