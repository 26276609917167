import localForage from 'localforage';
import thunk from 'redux-thunk';
import { applyMiddleware, createStore as reduxCreateStore, compose } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import rootReducer from './reducers';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
   key: 'root',
   storage: localForage,
   whitelist: ['media', 'home', 'cart', 'common', 'notifications'],
   stateReconciler: autoMergeLevel2,
};
// const persistedReducers = persistReducer(persistConfig, rootReducer);

// const composeEnhancers =
//    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//       ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//            actionsBlacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
//         })
//       : compose;

// const enhancer = composeEnhancers(applyMiddleware(thunk));
// const store = createStore(persistedReducers, enhancer);
// const persistor = persistStore(store);

// store.subscribe(() => {
//    console.log(store.getState());
// });

// export { store, persistor };

// export const dispatch = (action) => {
//    return store.dispatch(action);
// };

// export const getState = () => {
//    return store.getState();
// };
const persistedReducers = persistReducer(persistConfig, rootReducer);
const composeEnhancers =
   typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
           actionsBlacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
        })
      : compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));

// ver 2
let storeCache;
export const createStore = () => {
   const store = reduxCreateStore(persistedReducers, enhancer);
   storeCache = store;
   store.subscribe(() => {
      console.log(store.getState());
   });
   return {
      store: store,
      persistor: persistStore(store),
   };
};

export const dispatch = (action) => {
   return storeCache.dispatch(action);
};

export const getState = () => {
   return storeCache.getState();
};
