import { FETCH_HOME_NEW_PRODUCTS, FETCH_HOME_BEST_SELLERS } from "../constants/actions";
import { dispatch } from "@store/store";
import Request from "./utils/Request";

const getNewArrivals = async () => {
   return await dispatch(Request.post(FETCH_HOME_NEW_PRODUCTS, {
      data: {
         page: 1,
         page_size: 4,
         order_by: 'created_at',
         sort_type: 'DESC',
      },
   }));
}

const getBestSellers = async () => {
   return await dispatch(Request.post(FETCH_HOME_BEST_SELLERS, {
      data: {
         page: 1,
         page_size: 4,
         order_by: 'sold',
         sort_type: 'DESC',
      },
   }));
}

export const homeService = {
   getNewArrivals,
   getBestSellers,
}