import * as actions from "@constants/actions";

const initialState = {
   data: {},
   loading: false,
   error: null,
}

const setData = (currentData, payload) => {
   const prevContent = { ...(currentData.content || {}) };
   let newData = { ...currentData, ...payload };
   if (newData.content) {
      newData.content = { ...prevContent };
      payload.content.forEach(item => {
         newData.content[item.uid] = item;
      });
   }
   return newData;
}

const categoriesReducer = (state = initialState, action) => {
   switch (action.type) {
      case actions.FETCH_CATEGORIES__PENDING:
         return {
            ...state,
            loading: true,
            data: {
               ...state.data,
               search: action?.payload?.search || '',
            }
         };
      case actions.FETCH_CATEGORIES__FULFILLED:
         return {
            ...state,
            data: setData(state.data, action.payload),
            error: null,
            loading: false,
         };
      case actions.FETCH_CATEGORIES__REJECTED:
         return {
            ...state,
            error: action.payload,
            loading: false,
         };
      default:
         return state
   }
}

export default categoriesReducer;