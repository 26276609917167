import { cartService } from '@services/cartService';
import { notificationService } from '@services/notificationService';
import { getProfileSelector } from '@utils/selectors';
import React, { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const BackgroundLoader = memo((props) => {
   const { location } = props;
   const { data: userProfile } = useSelector(getProfileSelector);
   const notiCanceler = useRef(null);
   const cartCanceler = useRef(null);
   const userProfileRef = useRef(userProfile);
   useEffect(() => {
      userProfileRef.current = userProfile;
   });

   const onChange = () => {
      const loadNoti = async () => {
         notiCanceler.current = await notificationService.fetchUserNotificationsAmount();
      };
      const loadCart = async () => {
         cartCanceler.current = await cartService.getDetails();
      };
      if (userProfileRef.current) {
         loadNoti();
         loadCart();
      }
   };

   useEffect(() => {
      let hidden = 'hidden';

      // Standards:
      if (hidden in document) document.addEventListener('visibilitychange', onChange);
      else if ((hidden = 'mozHidden') in document)
         document.addEventListener('mozvisibilitychange', onChange);
      else if ((hidden = 'webkitHidden') in document)
         document.addEventListener('webkitvisibilitychange', onChange);
      else if ((hidden = 'msHidden') in document)
         document.addEventListener('msvisibilitychange', onChange);
      // IE 9 and lower:
      else if ('onfocusin' in document)
         document.onfocusin = document.onfocusout = onChange;
      // All others:
      else
         window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onChange;

      // set the initial state (but only if browser supports the Page Visibility API)
      if (document[hidden] !== void 0) {
         onChange({ type: document[hidden] ? 'blur' : 'focus' });
      }
   }, []);

   useEffect(() => {
      onChange();
      return () => {
         if (notiCanceler.current) {
            notiCanceler.current.cancel();
         }
         if (cartCanceler.current) {
            cartCanceler.current.cancel();
         }
      };
   }, [location, userProfile, location.pathname]);

   return props.children;
});

export default BackgroundLoader;
