import React from 'react';

const IconCookiesDisabled = () => (
   <svg width={120} height={120} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         d="M100.678 102.678C95.6758 107.681 89.6605 110.669 84.0126 111.491C78.3649 112.312 73.1534 110.967 69.593 107.406C66.0326 103.846 64.6869 98.6343 65.5085 92.9866C66.3301 87.3387 69.318 81.3235 74.3207 76.3208C79.3234 71.3181 85.3387 68.3301 90.9865 67.5085C96.6343 66.6869 101.846 68.0327 105.406 71.5931C108.967 75.1535 110.312 80.3649 109.491 86.0127C108.669 91.6606 105.681 97.6758 100.678 102.678Z"
         fill="#F2F2F2"
         stroke="#828282"
         strokeWidth={2}
      />
      <path
         d="M92.0213 94.0526C88.8284 97.2454 85.1469 99.2787 81.8149 100.021C78.469 100.766 75.626 100.19 73.8501 98.4145C72.0741 96.6385 71.4986 93.7955 72.2437 90.4496C72.9858 87.1176 75.0191 83.4361 78.212 80.2433C81.4048 77.0504 85.0863 75.0171 88.4183 74.275C91.7642 73.5299 94.6072 74.1054 96.3832 75.8814C98.1591 77.6573 98.7347 80.5003 97.9895 83.8462C97.2474 87.1782 95.2141 90.8597 92.0213 94.0526Z"
         fill="#F9F9F9"
         stroke="#828282"
         strokeWidth={2}
      />
      <path
         d="M81.74 81.7407C71.5042 91.9766 59.2324 98.1815 47.7007 99.9743C36.168 101.767 25.4533 99.1451 18.1544 91.8462C10.8555 84.5473 8.23326 73.8325 10.0262 62.2999C11.8191 50.7682 18.024 38.4964 28.2599 28.2605C38.4958 18.0247 50.7675 11.8197 62.2993 10.0269C73.8319 8.23391 84.5466 10.8561 91.8455 18.155C99.1445 25.4539 101.767 36.1687 99.9737 47.7013C98.1808 59.233 91.9759 71.5048 81.74 81.7407Z"
         fill="#F2F2F2"
         stroke="#828282"
         strokeWidth={2}
      />
      <path
         d="M67.7802 67.9276C61.4002 74.3076 53.9336 78.3291 47.0576 79.7096C40.1731 81.0919 34.0049 79.8148 29.9891 75.799C25.9733 71.7832 24.6962 65.6149 26.0784 58.7305C27.4589 51.8544 31.4804 44.3879 37.8604 38.0079C44.2405 31.6278 51.707 27.6064 58.583 26.2259C65.4675 24.8436 71.6357 26.1207 75.6515 30.1365C79.6673 34.1523 80.9444 40.3206 79.5622 47.205C78.1817 54.0811 74.1602 61.5476 67.7802 67.9276Z"
         fill="#F9F9F9"
         stroke="#828282"
         strokeWidth={2}
      />
   </svg>
);

export default IconCookiesDisabled;
