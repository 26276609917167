import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { navigate } from 'gatsby';
import { storeDispatcher } from '../../../store/dispatcher';
import constant from '../../../utils/constant';
import { message } from '../../../components/Common/Message';
import { ErrorDetector } from './ErrorDetector';
import { tokenUtil } from '../../../utils/token';
import Error from './Error';
import Content404 from '@pages/404';
import { connect } from 'react-redux';
import { UserStatus } from '@utils/enums/UserStatus';
import Page500 from '@views/default-layout/Page500';
import Page403 from '@views/default-layout/Page403';
import PageDisabledCookies from '@views/default-layout/PageDisabledCookies';

class ErrorBoundary extends PureComponent {
   state = {
      error: null,
   };
   _mouted = false;

   componentWillUnmount() {
      this._mouted = false;
      storeDispatcher.resetError();
   }

   componentDidMount() {
      this._mouted = true;
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
         this.setState({
            error: null,
         });
         storeDispatcher.resetError();
      }
   }

   handleError = (errorCode) => {
      let error = null;
      if (errorCode === Error.INVALID_TOKEN && this._mouted) {
         // error disabled cookies
         if (navigator && !navigator.cookieEnabled) {
            error = Error.DISABLED_COOKIES;
         } else {
            const { userProfile } = this.props;
            if (userProfile?.status === UserStatus.LOCKED) {
               message.error('Your account has been locked.');
               navigate(constant.ROUTE_HOME);
            } else if (userProfile?.status === UserStatus.REMOVED) {
               message.error('Your account has been removed.');
               navigate(constant.ROUTE_HOME);
            } else {
               if (tokenUtil.getToken()) {
                  message.error('Your session has expired. Please log in again.');
               } else {
                  message.error('Please login first.');
               }
               tokenUtil.clearUserData();
               let backUrl = encodeURIComponent(this.props.location.href);
               navigate(`${constant.ROUTE_LOGIN}?${constant.backUrlQuery}=${backUrl}`);
            }
         }
      } else if (errorCode) {
         error = errorCode;
      }
      if (error !== this.state.error) {
         this.setState({
            error,
         });
      }
   };

   render() {
      if (!this.state.error) {
         return (
            <>
               <ErrorDetector onError={this.handleError} />
               {this.props.children}
            </>
         );
      }
      const ErrorPage = {
         [`${Error.DISABLED_COOKIES}`]: PageDisabledCookies,
         // [`${Error.MAINTENANCE}`]: Maintenance,
         [`${Error.FORBIDDEN}`]: Page403,
         [`${Error.PAGE_NOT_FOUND}`]: Content404,
         [`${Error.INTERNAL_SERVER_ERROR}`]: Page500,
      }[this.state.error];

      if (ErrorPage) {
         return ReactDOM.createPortal(<ErrorPage />, document.querySelector('body'));
      }
      return null;
   }
}

const mappingState = (state, ownProps) => {
   return {
      userProfile: state.profile.data,
   };
};

export default connect(mappingState)(ErrorBoundary);
