import Breakpoint from '@components/Breakpoint/Breakpoint';
import { functionUtils } from '@utils/function';
import React, { memo, useEffect } from 'react';
import ReactDOM from 'react-dom';

const MbBackdropContent = memo(({ visible, onClick }) => {
   useEffect(() => {
      if (visible) {
         functionUtils.setModalOpen(true);
      } else {
         functionUtils.setModalOpen(false);
      }

      return () => {
         functionUtils.setModalOpen(false);
      };
   }, [visible]);

   return ReactDOM.createPortal(
      <div
         style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '0 !important',
            height: '0 !important',
            pointerEvents: 'none',
         }}
      >
         <div className={`mb-menu-backdrop ${visible ? 'open' : ''}`} onClick={onClick} />
      </div>,
      document.querySelector('body')
   );
});

const MbBackdrop = memo((props) => {
   return (
      <Breakpoint md down>
         <MbBackdropContent {...props} />
      </Breakpoint>
   );
});

export default MbBackdrop;
