import { CurrencyUnit } from '@utils/enums/CurrencyUnit';

const ROUTE_HOME = '/';
const ROUTE_PRODUCTS = '/products';
const ROUTE_CART = '/cart';
const ROUTE_CHECKOUT = '/checkout';
const ROUTE_LOGIN = '/login';
const ROUTE_REGISTER = '/register';
const ROUTE_ACTIVATE = '/activate';
const ROUTE_RESET_PASSWORD = '/reset-password';
const ROUTE_CONTACT = '/contact';
const ROUTE_PROFILE_INFO = '/profile/info';
const ROUTE_PROFILE_ORDER = '/profile/order';
const ROUTE_PROFILE_WISHLIST = '/profile/wishlist';
const ROUTE_PROFILE_NOTIFICATIONS = '/profile/notifications';
const ROUTE_FEEDBACK = '/feedback';
const ROUTE_PRIVACY_POLICY = '/privacy-policy';
const ROUTE_SHIPPING_POLICY = '/shipping-policy';

// admin routes
const ROUTE_ADMIN = '/admin';
const ROUTE_ADMIN_PRODUCTS = `${ROUTE_ADMIN}/products`;
const ROUTE_ADMIN_CATEGORIES = `${ROUTE_ADMIN}/categories`;
const ROUTE_ADMIN_USERS = `${ROUTE_ADMIN}/users`;
const ROUTE_ADMIN_NEW_PRODUCT = `${ROUTE_ADMIN}/products/new`;
const ROUTE_ADMIN_TAGS = `${ROUTE_ADMIN}/tags`;
const ROUTE_ADMIN_OPTIONS = `${ROUTE_ADMIN}/options`;
const ROUTE_ADMIN_WHOLESALE_TYPES = `${ROUTE_ADMIN}/wholesale-types`;
const ROUTE_ADMIN_EVENTS = `${ROUTE_ADMIN}/events`;
const ROUTE_ADMIN_NEW_EVENT = `${ROUTE_ADMIN}/events/new`;
const ROUTE_ADMIN_NOTIFICATIONS = `${ROUTE_ADMIN}/notifications`;
const ROUTE_ADMIN_SHIPMENT = `${ROUTE_ADMIN}/shipment`;
const ROUTE_ADMIN_SHIPMENT_FEES = `${ROUTE_ADMIN}/shipment/fees`;
const ROUTE_ADMIN_FEEDBACK = `${ROUTE_ADMIN}/feedback`;
const ROUTE_ADMIN_FEEDBACK_CATEGORIES = `${ROUTE_ADMIN}/feedback/categories`;
const ROUTE_ADMIN_SETTING = `${ROUTE_ADMIN}/setting`;

// constant
const TOKEN_VARIABLE = 'VGZ_TOKEN';
const DOMAIN_NAME = 'vgzcustom.com';
const DEVICE_ID_VARIABLE = 'VGZ_DEVICE_ID';
const backUrlQuery = 'back_url';
const COUPONS_VARIABLE = 'vgz_coupons';
const defaultPagesize = 25;
const delaySearch = 300;
const maxProductNumberToShowWarningInCart = 10;

const dateTimeFormat = 'MMMM D, YYYY - LT';
const dateFormat = 'MMMM D, YYYY';
const timeFormat = 'LT';
const dateValueFormat = 'YYYY-MM-DD';
const timeValueFormat = 'HH:mm';
const datetimeValueFormat = 'YYYY-MM-DD HH:mm:ss';
const timeFormatWithSeconds = 'HH:mm:ss';
const acceptedUploadFormat = '.jpg, .png';
const acceptedImageFormat = '.jpg, .png';
const TINYMCE_API_KEY = process.env.GATSBY_TINYMCE_API_KEY;
const currencyUnits = [
   {
      label: 'USD',
      value: CurrencyUnit.USD,
   },
   {
      label: 'VND',
      value: CurrencyUnit.VND,
   },
];
const DEFAULT_CURRENCY = CurrencyUnit.USD;
const DEFAULT_WEIGHT_UNIT = 'KG';
const SOCIAL_FACEBOOK = 'https://www.facebook.com/groups/1783824958487717';
const SOCIAL_INSTAGRAM = 'https://www.instagram.com/vgzcustom/';
const CONTACT_PHONE_NO = '+84 932 063 203';
const CONTACT_EMAIL = 'vgzcustoms@gmail.com';
const STRIPE_PUBLIC_KEY = 'pk_live_51K6JSCDzC8L5ERY7Em7JGOLR88dxkEVwLALsi4Uv7gbkNDgL2tPy4rOU0Os2Rxi9SVGeOTRQvGleP4Qjl4f6jR7300tl9bjpgX';
const MAX_PAYMENT_TOTAL = 999999.99;

const constant = {
   ROUTE_HOME,
   ROUTE_PRODUCTS,
   ROUTE_CART,
   ROUTE_CHECKOUT,
   ROUTE_LOGIN,
   ROUTE_REGISTER,
   ROUTE_ACTIVATE,
   ROUTE_RESET_PASSWORD,
   ROUTE_CONTACT,
   ROUTE_PROFILE_INFO,
   ROUTE_PROFILE_ORDER,
   ROUTE_PROFILE_WISHLIST,
   ROUTE_PROFILE_NOTIFICATIONS,
   ROUTE_FEEDBACK,
   ROUTE_PRIVACY_POLICY,
   ROUTE_SHIPPING_POLICY,

   ROUTE_ADMIN,
   ROUTE_ADMIN_PRODUCTS,
   ROUTE_ADMIN_CATEGORIES,
   ROUTE_ADMIN_USERS,
   ROUTE_ADMIN_NEW_PRODUCT,
   ROUTE_ADMIN_TAGS,
   ROUTE_ADMIN_OPTIONS,
   ROUTE_ADMIN_WHOLESALE_TYPES,
   ROUTE_ADMIN_EVENTS,
   ROUTE_ADMIN_NEW_EVENT,
   ROUTE_ADMIN_NOTIFICATIONS,
   ROUTE_ADMIN_SHIPMENT,
   ROUTE_ADMIN_SHIPMENT_FEES,
   ROUTE_ADMIN_FEEDBACK,
   ROUTE_ADMIN_FEEDBACK_CATEGORIES,
   ROUTE_ADMIN_SETTING,

   TOKEN_VARIABLE,
   DOMAIN_NAME,
   DEVICE_ID_VARIABLE,
   COUPONS_VARIABLE,
   backUrlQuery,
   defaultPagesize,
   delaySearch,
   maxProductNumberToShowWarningInCart,

   dateTimeFormat,
   dateFormat,
   timeFormat,
   dateValueFormat,
   timeValueFormat,
   datetimeValueFormat,
   timeFormatWithSeconds,
   acceptedUploadFormat,
   acceptedImageFormat,
   TINYMCE_API_KEY,
   currencyUnits,
   DEFAULT_CURRENCY,
   DEFAULT_WEIGHT_UNIT,
   SOCIAL_FACEBOOK,
   SOCIAL_INSTAGRAM,
   CONTACT_PHONE_NO,
   CONTACT_EMAIL,
   STRIPE_PUBLIC_KEY,
   MAX_PAYMENT_TOTAL,
};
export default constant;
