import React, { Fragment, PureComponent } from 'react';

class FooterSkeleton extends PureComponent {
   render() {
      return (
         <>
            <div style={{
               width: '100%',
               height: '392px',
               background: 'transparent',
            }}
               className="skeleton-box"
            />
         </>
      );
   }
}

export default FooterSkeleton;