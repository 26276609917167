import { apiConstant } from '@apis/constant';
import { tokenUtil } from '@utils/token';
import { fetch } from '@apis/ServiceController';
import { adminService } from './adminService';
import { dispatch } from '@store/store';
import Request from './utils/Request';
import {
   FETCH_USER_NOTIFICATIONS,
   FETCH_USER_NOTIFICATIONS_AMOUNT,
   FETCH_POPUP_USER_NOTIFICATIONS,
} from '../constants/actions';
import constant from '@utils/constant';

const getNotificationsMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_NOTIFICATIONS_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const getNotificationDetails = async (uid, cancelToken) => {
   return fetch({
      method: 'get',
      url: apiConstant.GET_NOTIFICATION_DETAILS,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const createNotification = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_NOTIFICATION,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const updateNotification = async (uid, body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_NOTIFICATION,
      params: {
         uid,
      },
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const deleteNotification = async (uids, cancelToken) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_NOTIFICATIONS,
      data: {
         uids,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const resendNotification = async (uid, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.RESEND_NOTIFICATION,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const getUserNotificationDetails = async (uid, cancelToken) => {
   return fetch({
      method: 'get',
      url: apiConstant.GET_USER_NOTIFICATION_DETAILS,
      params: {
         uid,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const getUserNotificationMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_USER_NOTIFICATION_DETAILS,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const readNotification = async (uids, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.READ_NOTIFICATION,
      data: {
         uids,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const readAllNotification = async (cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.READ_ALL_NOTIFICATION,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const fetchUserNotifications = async (body) => {
   return await dispatch(
      Request.post(FETCH_USER_NOTIFICATIONS, {
         data: body,
      })
   );
};
const fetchPopupUserNotifications = async () => {
   return await dispatch(
      Request.post(FETCH_POPUP_USER_NOTIFICATIONS, {
         data: {
            page: 1,
            page_size: constant.defaultPagesize,
         },
      })
   );
};

const fetchUserNotificationsAmount = async (body) => {
   return await dispatch(Request.get(FETCH_USER_NOTIFICATIONS_AMOUNT));
};

export const notificationService = {
   getNotificationsMatrix,
   getNotificationDetails,
   createNotification,
   updateNotification,
   deleteNotification,
   resendNotification,
   getUserNotificationDetails,
   getUserNotificationMatrix,
   readNotification,
   readAllNotification,
   fetchUserNotifications,
   fetchUserNotificationsAmount,
   fetchPopupUserNotifications,
};
