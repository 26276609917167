import React, { PureComponent } from 'react';

class IconCancel extends PureComponent {
   render() {
      return (
         <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path d="M3 3L21 21" stroke="#828282" strokeWidth="1.5" />
            <path d="M3 21L21 3" stroke="#828282" strokeWidth="1.5" />
         </svg>
      );
   }
}

export default IconCancel;