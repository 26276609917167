import Error from '@views/error/ErrorBoundary/Error';
import axios, { AxiosResponse } from 'axios';

interface IResponse {
   isSuccess: boolean;
   errorCode: number;
   message: string;
   data: any;
}

export default class Response implements IResponse {
   isSuccess: boolean = false;
   errorCode: number = 0;
   message: string = '';
   data: any = null;

   public static dispatchResponse = (dispatch: any, action: string) => (response: AxiosResponse) => {
      const res = Response.getResponse(response.data);
      console.log(res);
      if (res.isSuccess) {
         dispatch(Response.onSuccess(action)(res));
      } else {
         dispatch(Response.onError(action)(res.message, res.errorCode));
         Response.commonErrorHandler(dispatch)(res.message, res.errorCode);
      }
   };

   public static dispatchError = (dispatch: any, action: string) => (error: Error) => {
      const res = Response.getResponse(error);
      dispatch(Response.onError(action)(res.message, res.errorCode));
      Response.commonErrorHandler(dispatch)(res.message, res.errorCode);
   };

   public static catchError = (dispatch: any, error: Error) => {
      if (axios.isCancel(error)) {
         // throw new Error(`Request cancelled: ${error.message}`);
      } else {
         // throw new Error(`Error retrieving response: ${error.message}`);
         Response.commonErrorHandler(dispatch)(error.message);
      }
   };

   private static onSuccess = (action: string) => (response: IResponse) => {
      console.log(`${action}__FULFILLED`);
      return {
         type: `${action}__FULFILLED`,
         payload: response.data,
      };
   };

   private static onError = (action: string) => (errorMessage: string, errorCode?: number) => {
      console.log(errorMessage);
      console.log(errorCode);
      return {
         type: `${action}__REJECTED`,
         payload: {
            code: errorCode,
            message: errorMessage,
         },
      };
   };

   private static commonErrorHandler = (dispatch: any) => (errorMessage: string, errorCode?: number) => {
      console.log(errorMessage);
      console.log(errorCode);
      if (errorMessage === 'Network Error' || errorMessage === 'Network request failed') {
         dispatch(() => {
            return {
               type: 'SET_ERROR',
               errorCode: Error.INTERNAL_SERVER_ERROR,
            };
         });
      }
   };

   private static getResponse = (responseBody: any, error?: any): IResponse => {
      const response = new Response();
      if (responseBody) {
         response.isSuccess = responseBody.errorCode === 0;
         response.errorCode = responseBody.errorCode || 0;
         response.message = responseBody.message || '';
         response.data = responseBody.data || null;
      }
      if (error) {
         response.message = String(error);
      }
      return response;
   };
}
