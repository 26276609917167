import React from 'react';
import './src/styles/styles.scss';
import 'animate.css/animate.min.css';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@popperjs/core/dist/umd/popper.min.js';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import { store, persistor } from './src/store/store';
import ContentLoading from './src/views_admin/ContentLoading';
import wrapWithProvider from './wrap-with-provider';

const wrapRootElement = wrapWithProvider;
// const wrapRootElement = ({ element, ...args }) => {
//    console.log(args);
//    return (
//       <Provider store={store}>
//          <PersistGate loading={<ContentLoading isLoading={true} />} persistor={persistor}>
//             {element}
//          </PersistGate>
//       </Provider>
//    );
// };

export { wrapRootElement };

// export const onClientEntry = () => {
//    registerServiceWorker();
// };

// export const registerServiceWorker = () => {
//    if (typeof window !== 'undefined') {
//       // Do magic
//       navigator.serviceWorker
//          .register('./firebase-messaging-sw.js')
//          .then(function (registration) {
//             // Successful registration
//             console.log('Registration successful, scope is:', registration.scope);
//          })
//          .catch(function (err) {
//             // Failed registration, service worker won’t be installed
//             console.log('Service worker registration failed, error:', err);
//          });
//    }
// };
