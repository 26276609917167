import * as actions from "@constants/actions";

const initialState = {
   data: {},
}

const saveToData = (currentData, payload) => {
   let newData = { ...currentData };
   if (payload?.length) {
      payload.forEach(mediaItem => {
         if (mediaItem.uid) {
            newData[mediaItem.uid] = { ...mediaItem };
         }
      });
   }
   return newData;
}

const deleteFromData = (currentData, payload) => {
   let newData = { ...currentData };
   if (payload?.length) {
      payload.forEach(mediaUid => {
         if (newData[mediaUid]) {
            delete newData[mediaUid];
         }
      });
   }
   return newData;
}

const mediaReducer = (state = initialState, action) => {
   switch (action.type) {
      case actions.SAVE_TO_MEDIA:
         return {
            ...state,
            data: saveToData(state.data, action.payload)
         };

      case actions.DELETE_FROM_MEDIA:
         return {
            ...state,
            data: deleteFromData(state.data, action.payload)
         };

      default:
         return state;
   }
}

export default mediaReducer;