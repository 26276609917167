import * as actions from '@constants/actions';

const initialState = {
   data: {
      cart: null,
      items: {},
   },
   localCart: {},
   loading: false,
   error: null,
};

// cart
const setData = (currentData, payload) => {
   let newData = { ...currentData };
   const { cart, items } = payload;
   if (cart !== void 0 && items) {
      newData.cart = cart;
      newData.items = {};
      items.forEach((item) => {
         newData.items = {
            ...newData.items,
            [item.uid]: item,
         };
      });
   }
   return newData;
};

const updateCart = (currentData, payload) => {
   let newData = { ...currentData };
   const { cart, items } = payload;
   if (cart && items) {
      newData.cart = { ...cart };
      newData.items = {};
      items.forEach((item) => {
         newData.items = {
            ...newData.items,
            [item.uid]: item,
         };
      });
   }
   return newData;
};

// local cart
const updateLocalCart = (currentData, payload) => {
   let newData = { ...currentData };
   if (payload?.item_id) {
      newData[payload.item_id] = {
         ...newData[payload.item_id],
         ...(payload || {}),
      };
   }
   return newData;
};
const pushToLocalCart = (currentData, payload) => {
   let newData = { ...currentData };
   if (payload?.item_id) {
      const currentItem = newData[payload.item_id];
      if (currentItem) {
         const quantity = (currentItem?.quantity || 0) + (payload?.quantity || 0);
         newData[payload.item_id] = {
            ...newData[payload.item_id],
            ...(payload || {}),
            quantity,
         };
      } else {
         newData[payload.item_id] = payload;
      }
   }
   return newData;
};
const removeFromLocalCart = (currentData, payload) => {
   let newData = { ...currentData };
   if (typeof payload === 'string' && newData[payload]) {
      delete newData[payload];
   }
   return newData;
};

/**
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
const cartReducer = (state = initialState, action) => {
   switch (action.type) {
      case actions.FETCH_CART__PENDING:
         return {
            ...state,
            loading: true,
            error: null,
         };
      case actions.FETCH_CART__FULFILLED:
         return {
            ...state,
            data: setData(state.data, action.payload),
            loading: false,
            error: null,
         };
      case actions.FETCH_CART__REJECTED:
         return {
            ...state,
            loading: false,
            error: action.payload,
         };

      // add to cart
      case actions.ADD_TO_CART__PENDING:
         return {
            ...state,
            loading: true,
            error: null,
         };
      case actions.ADD_TO_CART__FULFILLED:
         return {
            ...state,
            data: updateCart(state.data, action.payload),
            loading: false,
            error: null,
         };
      case actions.ADD_TO_CART__REJECTED:
         return {
            ...state,
            loading: false,
            error: action.payload,
         };

      // update cart item
      case actions.UPDATE_CART_ITEM__PENDING:
         return {
            ...state,
            loading: true,
            error: null,
         };
      case actions.UPDATE_CART_ITEM__FULFILLED:
         return {
            ...state,
            data: updateCart(state.data, action.payload),
            loading: false,
            error: null,
         };
      case actions.UPDATE_CART_ITEM__REJECTED:
         return {
            ...state,
            loading: false,
            error: action.payload,
         };

      // delete cart item
      case actions.DELETE_CART_ITEM__PENDING:
         return {
            ...state,
            loading: true,
            error: null,
         };
      case actions.DELETE_CART_ITEM__FULFILLED:
         return {
            ...state,
            data: updateCart(state.data, action.payload),
            loading: false,
            error: null,
         };
      case actions.DELETE_CART_ITEM__REJECTED:
         return {
            ...state,
            loading: false,
            error: action.payload,
         };

      // remove error
      case actions.CLEAR_CART_ERROR:
         return {
            ...state,
            error: null,
         };

      // clear cart
      case actions.UPDATE_ITEM_IN_LOCAL_CART:
         return {
            ...state,
            localCart: updateLocalCart(state.localCart, action.payload),
         };
      case actions.PUSH_ITEM_TO_LOCAL_CART:
         return {
            ...state,
            localCart: pushToLocalCart(state.localCart, action.payload),
         };
      case actions.REMOVE_ITEM_FROM_LOCAL_CART:
         return {
            ...state,
            localCart: removeFromLocalCart(state.localCart, action.payload),
         };
      case actions.CLEAR_LOCAL_CART:
         return {
            ...state,
            localCart: {},
         };

      default:
         return state;
   }
};

export default cartReducer;
