import React, { PureComponent } from 'react';

class IconInstagram extends PureComponent {
   render() {
      return (
         <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M12.145 4.85201C12.6752 4.85201 13.105 4.4222 13.105 3.89201C13.105 3.36181 12.6752 2.93201 12.145 2.93201C11.6148 2.93201 11.185 3.36181 11.185 3.89201C11.185 4.4222 11.6148 4.85201 12.145 4.85201Z"
               fill="currentColor"
            />
            <path
               d="M8 12C5.794 12 4 10.206 4 8C4 5.794 5.794 4 8 4C10.206 4 12 5.794 12 8C12 10.206 10.206 12 8 12ZM8 6C6.897 6 6 6.897 6 8C6 9.103 6.897 10 8 10C9.103 10 10 9.103 10 8C10 6.897 9.103 6 8 6Z"
               fill="currentColor"
            />
            <path
               d="M12 16H4C1.944 16 0 14.056 0 12V4C0 1.944 1.944 0 4 0H12C14.056 0 16 1.944 16 4V12C16 14.056 14.056 16 12 16ZM4 2C3.065 2 2 3.065 2 4V12C2 12.953 3.047 14 4 14H12C12.935 14 14 12.935 14 12V4C14 3.065 12.935 2 12 2H4Z"
               fill="currentColor"
            />
         </svg>
      );
   }
}

export default IconInstagram;