import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, description, lang, meta, image, article, keywords }) => {
   const { pathname } = useLocation();
   const { site } = useStaticQuery(query);
   const {
      defaultTitle,
      defaultDescription,
      siteUrl,
      defaultKeywords,
      defaultImage,
   } = site.siteMetadata;

   const seo = {
      title: title || defaultTitle,
      description: description || defaultDescription,
      image: `${image || defaultImage}`,
      url: `${siteUrl}${pathname}`,
      keywords: keywords || defaultKeywords,
   };

   return (
      <Helmet
         title={seo.title}
         titleTemplate={title ? `%s | ${defaultTitle}` : defaultTitle}
         htmlAttributes={{
            lang,
         }}
         meta={[
            {
               name: `description`,
               content: seo.description,
            },
            {
               name: `keywords`,
               content: seo.keywords,
            },
            {
               property: `og:title`,
               content: seo.title,
            },
            {
               property: `og:description`,
               content: seo.description,
            },
            {
               property: `og:url`,
               content: seo.url,
            },
            {
               property: `og:type`,
               content: `article`,
            },
            {
               property: `og:image`,
               content: seo.image,
            },
            {
               name: `twitter:card`,
               content: `summary_large_image`,
            },
            {
               name: `twitter:image`,
               content: seo.image,
            },
            {
               name: `twitter:creator`,
               content: site.siteMetadata?.author || ``,
            },
            {
               name: `twitter:title`,
               content: seo.title,
            },
            {
               name: `twitter:description`,
               content: seo.description,
            },
         ].concat(meta)}
      >
         {/* <meta name="description" content={seo.description} /> */}
         <meta name="image" content={seo.image} />
         {/* {seo.url && <meta property="og:url" content={seo.url} />} */}
         {/* {(article ? true : null) && <meta property="og:type" content="article" />} */}
         {/* {seo.title && <meta property="og:title" content={seo.title} />} */}
         {/* {seo.description && (
            <meta property="og:description" content={seo.description} />
         )} */}
         {/* {seo.image && <meta property="og:image" content={seo.image} />} */}

         {/* <meta name="twitter:card" content="summary_large_image" /> */}
         {/* {seo.title && <meta name="twitter:title" content={seo.title} />} */}
         {/* {seo.description && (
            <meta name="twitter:description" content={seo.description} />
         )} */}
         {seo.image && <meta name="twitter:image" content={seo.image} />}
      </Helmet>
   );
};
export default SEO;

SEO.propTypes = {
   title: PropTypes.string,
   description: PropTypes.string,
   image: PropTypes.string,
   article: PropTypes.bool,
   meta: PropTypes.arrayOf(PropTypes.object),
};
SEO.defaultProps = {
   lang: `en`,
   title: '',
   description: '',
   image: '',
   article: false,
   meta: [],
};

const query = graphql`
   query SEO {
      site {
         siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl: url
            defaultKeywords: keywords
            defaultImage: image
         }
      }
   }
`;
