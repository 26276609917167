import { storeDispatcher } from '@store/dispatcher';
import { checkIsAttachment } from '@utils/common';
import Error from '@views/error/ErrorBoundary/Error';
import axios from 'axios';
import { setError } from '../views/error/ErrorBoundary/ErrorDetector';
import { apiConstant } from './constant';
import ResponseModel from './models/ResponseModel';
import _includes from 'lodash/includes';

const _getResponse = (responseBody, error) => {
   let response = new ResponseModel();
   if (responseBody) {
      response.isSuccess = responseBody.errorCode === 0;
      response.errorCode = responseBody.errorCode || 0;
      response.message = responseBody.message || '';
      response.data = responseBody.data || null;
   }
   if (error) {
      response.message = String(error);
   }
   return response;
};

const _keywordsGetRequest = ['/get', '/matrix', '/details', '/all'];

const _commonErrorHandler = (errorCode, errorMessage, requestOptions) => {
   const url = requestOptions?.url;
   let isGetRequest = false;
   if (url && _keywordsGetRequest.some((keyword) => _includes(url, keyword))) {
      isGetRequest = true;
   }
   if (errorMessage === 'Network Error' || errorMessage === 'Network request failed') {
      setError(Error.INTERNAL_SERVER_ERROR);
   } else if (errorCode === 401 && navigator && !navigator.cookieEnabled) {
      setError(Error.DISABLED_COOKIES);
   } else if (isGetRequest && errorCode === 403) {
      setError(Error.FORBIDDEN);
   } else if (isGetRequest && errorCode === 404) {
      setError(Error.PAGE_NOT_FOUND);
   }
};

const _resquestWithAttachments = [
   apiConstant.GET_PRODUCTS_MATRIX,
   apiConstant.GET_PRODUCT_DETAILS,
   apiConstant.GET_ATTACHMENTS_MATRIX,
   apiConstant.GET_ATTACHMENT_BY_UID,
];
const _serviceResponseMiddlewareHandler = (response, configOptions) => {
   if (_resquestWithAttachments.some((res) => res === configOptions?.url)) {
      if (response.isSuccess && response.data) {
         let attachments = [];
         const collectToAttachments = (item) => {
            if (checkIsAttachment(item)) {
               attachments.push({ ...item });
            }
         };
         collectToAttachments(response.data);
         if (response.data?.content?.length) {
            response.data.content.forEach((item) => {
               collectToAttachments(item);
               if (item?.attachments?.length) {
                  item.attachments.forEach((attItem) => {
                     collectToAttachments(attItem);
                  });
               }
            });
         }
         if (response.data?.attachments?.length) {
            response.data.attachments.forEach((item) => {
               collectToAttachments(item);
            });
         }
         if (attachments?.length) {
            storeDispatcher.saveToMedia(attachments);
         }
      }
   }
};

const fetch = async ({ ignoreFailCheck, ...options }) => {
   if (options && options.url) {
      let configOptions = {
         ...options,
         baseURL: apiConstant.API_URL,
         // withCredentials: true,
      };
      return axios(configOptions)
         .then(function (res) {
            console.log(res);
            const response = _getResponse(res.data);
            console.log(response);
            if (options.errorHandler && !response.isSuccess) {
               const hasError = options.errorHandler(
                  response.errorCode,
                  response.message,
                  configOptions
               );
               if (hasError) {
                  return response;
               }
            }
            if (!ignoreFailCheck) {
               _commonErrorHandler(response.errorCode, response.message, configOptions);
            }
            _serviceResponseMiddlewareHandler(response, configOptions);
            return response;
         })
         .catch(function (error) {
            console.log(error);
            const response = _getResponse(error);
            if (axios.isCancel(error)) {
               response.message = 'Request canceled';
            }
            if (options.errorHandler) {
               const hasError = options.errorHandler(
                  response.errorCode,
                  response.message,
                  configOptions
               );
               if (hasError) {
                  return response;
               }
            }
            if (!ignoreFailCheck && !axios.isCancel(error)) {
               _commonErrorHandler(response.errorCode, response.message, configOptions);
            }
            _serviceResponseMiddlewareHandler(response, configOptions);
            return response;
         });
   }
   return _getResponse(null);
};

const createCancelToken = () => {
   const CancelToken = axios.CancelToken;
   return CancelToken.source();
};

export { fetch, createCancelToken };
