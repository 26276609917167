import { cartService } from '@services/cartService';
import { configurationService } from '@services/configurationService';
import { homeService } from '@services/homeService';
import { notificationService } from '@services/notificationService';
import { storeDispatcher } from '@store/dispatcher';
import constant from '@utils/constant';
import usePrevious from '@utils/hooks/usePrevious';
import { getLocalCartSelector, getProfileSelector } from '@utils/selectors';
import { tokenUtil } from '@utils/token';
import React, { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { categoryService } from '../services/categoryService';
import { profileService } from '../services/profileService';

const RootLayout = memo((props) => {
   const { location } = props;
   const userProfileCanceler = useRef(null);
   const categoriesCanceler = useRef(null);
   const newArrivalsCanceler = useRef(null);
   const bestSellersCanceler = useRef(null);
   const configCanceler = useRef(null);

   const { data: userProfile } = useSelector(getProfileSelector);
   const localCartItems = useSelector(getLocalCartSelector);

   // profile
   useEffect(() => {
      const fetchUserProfile = async () => {
         userProfileCanceler.current = await profileService.loadUserProfile();
      };
      const accessToken = tokenUtil.getToken();
      if (accessToken && !userProfile) {
         fetchUserProfile();
      } else if (!accessToken && userProfile) {
         storeDispatcher.removeUserProfile();
      }
      return () => {
         if (userProfileCanceler.current) {
            userProfileCanceler.current.cancel();
         }
      };
   }, [userProfile, location.pathname]);

   // init first data
   useEffect(() => {
      const initData = async () => {
         configCanceler.current = await configurationService.loadConfiguration();
         categoriesCanceler.current = await categoryService.loadCategories({
            page: 1,
            page_size: constant.defaultPagesize,
            order_by: 'updated_at',
            sort_type: 'DESC',
         });
         newArrivalsCanceler.current = await homeService.getNewArrivals();
         bestSellersCanceler.current = await homeService.getBestSellers();
      };
      loadCountry();
      initData();

      return () => {
         if (categoriesCanceler.current) {
            categoriesCanceler.current.cancel();
         }
         if (newArrivalsCanceler.current) {
            newArrivalsCanceler.current.cancel();
         }
         if (bestSellersCanceler.current) {
            bestSellersCanceler.current.cancel();
         }
         if (configCanceler.current) {
            configCanceler.current.cancel();
         }
      };
   }, []);

   // sync local cart
   useEffect(() => {
      if (localCartItems.length > 0 && userProfile) {
         storeDispatcher.clearLocalCart();
         console.log('syncLocalCart');
         cartService.syncLocalCart(localCartItems);
      }
   }, [localCartItems, userProfile]);

   const loadCountry = async () => {
      try {
         const response = await fetch('https://get.geojs.io/v1/ip/country.json');
         const data = await response.json();
         if (data) {
            storeDispatcher.setCountry(data);
         }
      } catch (err) {
         console.log(err);
         storeDispatcher.setCountry(null);
      }
   };

   return props.children;
});

export default RootLayout;
