import Error from '@views/error/ErrorBoundary/Error';
import { setError } from '../views/error/ErrorBoundary/ErrorDetector';

const handleError = (errorCode, errorMessage) => {
   if (errorCode === 401) {
      if (navigator && !navigator.cookieEnabled) {
         setError(Error.DISABLED_COOKIES);
         return true;
      }
      setError(Error.INVALID_TOKEN);
      return true;
   }
   return false;
};

export const adminService = {
   handleError,
};
