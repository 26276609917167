import IconUserNotification from '@icons/IconUserNotification';
import { notificationService } from '@services/notificationService';
import { getDisplayTimeLeft } from '@utils/common';
import constant from '@utils/constant';
import {
   getPopupNotificationsCountSelector,
   getPopupNotificationsSelector,
   getProfileSelector,
} from '@utils/selectors';
import ContentLoading from '@views_admin/ContentLoading';
import { Menu, Dropdown } from 'antd';
import { Link, navigate } from 'gatsby';
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

const Notifications = memo(() => {
   const [visible, setVisible] = React.useState(false);
   const amount = useSelector(getPopupNotificationsCountSelector);
   const { data: userProfile } = useSelector(getProfileSelector);
   const { unread = 0 } = amount || {};

   const loggedIn = useMemo(() => {
      return userProfile ? true : false;
   }, [userProfile]);

   const renderContent = () => {
      return (
         <Menu className="common-menu notifications-menu-popover">
            <NofiticationsPopover
               isOpen={visible}
               unread={unread}
               loggedIn={loggedIn}
               close={() => setVisible(false)}
            />
         </Menu>
      );
   };

   return (
      <Dropdown
         overlayClassName="common-dropdown notifications-menu-dropdown"
         overlay={renderContent()}
         placement="bottomRight"
         trigger="click"
         visible={visible}
         onVisibleChange={(v) => setVisible(v)}
      >
         <div className="menu-item">
            <div className="notifications-menu">
               <IconUserNotification />
               {unread !== 0 && loggedIn && (
                  <span className="count">{unread > 99 ? '99+' : unread}</span>
               )}
            </div>
         </div>
      </Dropdown>
   );
});

const NofiticationsPopover = memo(({ isOpen, unread, loggedIn, close }) => {
   const { data, loading } = useSelector(getPopupNotificationsSelector);
   const canceler = useRef(null);

   const { content = [] } = data || {};

   const loadData = async () => {
      canceler.current = await notificationService.fetchPopupUserNotifications();
   };

   useEffect(() => {
      if (loggedIn) {
         loadData();
      }
      return () => {
         if (canceler.current) {
            canceler.current.cancel();
         }
      };
   }, [unread, loggedIn]);

   const markAllAsRead = async () => {
      const response = await notificationService.readAllNotification();
      if (response.isSuccess) {
         notificationService.fetchUserNotificationsAmount();
      }
   };

   const markItemAsRead = useCallback(async (uid) => {
      const response = await notificationService.readNotification([uid]);
      if (response.isSuccess) {
         notificationService.fetchUserNotificationsAmount();
      }
   }, []);

   const maxContainerHeight = useMemo(() => {
      if (window) {
         let height = window.innerHeight - 80 - 30 - 48;
         if (content?.length > 0) {
            return height - 48;
         }
         return height;
      }
      return 484;
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isOpen, content]);

   return (
      <div className="notifications-popover">
         <div className="notifications-popover__header">
            <div className="title">
               <span>Notifications</span>
            </div>
            <div className="mark-all-as-read">
               {loggedIn && (
                  <button disabled={unread === 0} onClick={markAllAsRead}>
                     Mark all as Read
                  </button>
               )}
            </div>
         </div>
         <div className="notifications-popover__body">
            {loggedIn ? (
               <>
                  <ContentLoading isLoading={content?.length === 0 && loading} />
                  {content?.length === 0 && !loading && (
                     <div className="notifications-popover__body--empty">
                        No notifications yet
                     </div>
                  )}
                  {content?.length > 0 && (
                     <div
                        className="notifications-popover__body--container"
                        style={{ maxHeight: `${maxContainerHeight}px` }}
                     >
                        {content.map((item, index) => {
                           return (
                              <NotificationItem
                                 key={item.uid}
                                 {...item}
                                 onClick={markItemAsRead}
                                 close={close}
                              />
                           );
                        })}
                     </div>
                  )}
               </>
            ) : (
               <div className="notifications-popover__body--empty">
                  Please login to see notifications
               </div>
            )}
         </div>
         {content?.length > 0 && loggedIn && (
            <Link
               className="notifications-popover__footer"
               to={constant.ROUTE_PROFILE_NOTIFICATIONS}
               onClick={close}
            >
               <span>View all</span>
            </Link>
         )}
      </div>
   );
});

const NotificationItem = memo((props) => {
   const { uid, notification, created_at, read, onClick, close } = props;

   const handleClick = () => {
      if (!read) {
         onClick(uid);
      }
      localStorage['noti-item-focused'] = uid;
      navigate(constant.ROUTE_PROFILE_NOTIFICATIONS);
      close();
   };
   return (
      <div className="notifications-popover__body--item" onClick={handleClick}>
         {!read && <div className="notifications-item-read" />}
         <div className="notifications-popover__body--item-container">
            <div className="notifications-popover__body--item-header">
               <div className="notifications-popover__body--item-header--title">
                  {notification.title}
               </div>
               <div className="notifications-popover__body--item-header--time">
                  {getDisplayTimeLeft(created_at)}
               </div>
            </div>
            <div className="notifications-popover__body--item-content">
               <p>{notification.message}</p>
            </div>
         </div>
      </div>
   );
});

export default Notifications;
