const API_URL = process.env.GATSBY_API_URL;

// me
const GET_ME = '/users/me';
const LOGIN = '/oauth2/login';
const LOGOUT = '/oauth2/logout';
const UPDATE_PROFILE = '/users/update';

// category
const GET_CATEGORIES_MATRIX = '/categories/matrix';
const CREATE_CATEGORY = '/categories/create';
const UPDATE_CATEGORY = '/categories/update';
const DELETE_CATEGORY = '/categories/delete';

// tags
const GET_TAG_MATRIX = '/tags/matrix';
const CREATE_TAG = '/tags/create';
const UPDATE_TAG = '/tags/update';
const DELETE_TAG = '/tags/delete';

// product
const GET_PRODUCTS_MATRIX = '/products/matrix';
const CREATE_PRODUCT = '/products/create';
const UPDATE_PRODUCT = '/products/update';
const DELETE_PRODUCT = '/products/delete';
const RECOVER_PRODUCT = '/products/recover';
const GET_PRODUCT_DETAILS = '/products/details';
const UPDATE_PRODUCT_STATUSES = '/products/statuses/update';

// attachments
const GET_ATTACHMENTS_MATRIX = '/attachments/matrix';
const UPLOAD_ATTACHMENTS = '/attachments/upload';
const DELETE_ATTACHMENTS = '/attachments/delete';
const GET_ATTACHMENT_BY_UID = '/attachments/get_by_uid';

// option type - options
const GET_OPTION_TYPE_MATRIX = '/products/options/types/matrix';
const CREATE_OPTION_TYPE = '/products/options/types/create';
const UPDATE_OPTION_TYPE = '/products/options/types/update';
const GET_OPTION_MATRIX = '/products/options/matrix';
const CREATE_OPTION = '/products/options/create';
const UPDATE_OPTION = '/products/options/update';
const DELETE_OPTION = '/products/options/delete';

// price type
const CREATE_PRICE_TYPE = '/products/prices/types/create';
const UPDATE_PRICE_TYPE = '/products/prices/types/update';
const DELETE_PRICE_TYPE = '/products/prices/types/delete';
const GET_PRICE_TYPE_MATRIX = '/products/prices/types/matrix';

// users
const CREATE_USER = '/users/create';
const UPDATE_USER = '/users/update';
const DELETE_USER = '/users/delete';
const RECOVER_USER = '/users/recover';
const LOCK_USER = '/users/lock';
const UNLOCK_USER = '/users/unlock';
const RESET_PASSWORD = '/users/password/reset';
const CHANGE_PASSWORD = '/users/password/change';
const REQUEST_ACTIVATION = '/users/request_activation';
const ACTIVATE_USER = '/users/activate';
const GET_USER_MATRIX = '/users/matrix';

// reviews
const GET_REVIEW_MATRIX = '/reviews/matrix';
const CREATE_REVIEW = '/reviews/create';
const LOCK_REVIEW = '/reviews/lock';
const UNLOCK_REVIEW = '/reviews/unlock';
const DELETE_REVIEW = '/reviews/delete';

// carts
const GET_LOCAL_CART_DETAILS = '/carts/local_details';

// seller
const GET_SELLER_DETAILS = '/users/sellers/details';
const REGISTER_SELLER = '/users/sellers/register';
const DELETE_SELLER = '/users/sellers/delete';

// payment
const CREATE_CARD = '/payments/cards/create';
const SET_DEFAULT_CARD = '/payments/cards/set_default';
const GET_ALL_CARD = '/payments/cards/all';
const DELETE_CARD = '/payments/cards/delete';
const CREATE_CHECKOUT = '/payments/create';

// address
const CREATE_ADDRESS = '/users/addresses/create';
const UPDATE_ADDRESS = '/users/addresses/update';
const DELETE_ADDRESSES = '/users/addresses/delete';
const GET_ADDRESSES_MATRIX = '/users/addresses/matrix';
const SET_DEFAULT_ADDRESS = '/users/addresses/set_default';

// wishlist
const ADD_PRODUCT_TO_WISHLIST = '/products/favorites/create';
const DELETE_FROM_WISHLIST = '/products/favorites/delete';
const GET_WISHLIST_MATRIX = '/products/favorites/matrix';

// events
const GET_EVENT_MATRIX = '/events/matrix';
const DELETE_EVENT = '/events/delete';
const LOCK_EVENT = '/events/lock';
const UNLOCK_EVENT = '/events/unlock';
const GET_EVENT_DETAILS = '/events/details';
const CREATE_EVENT = '/events/create';
const UPDATE_EVENT = '/events/update';
const CHECK_COUPON = '/events/coupons/check';

// notifications
const GET_NOTIFICATIONS_MATRIX = '/notifications/matrix';
const GET_NOTIFICATION_DETAILS = '/notifications/details';
const CREATE_NOTIFICATION = '/notifications/create';
const DELETE_NOTIFICATIONS = '/notifications/delete';
const UPDATE_NOTIFICATION = '/notifications/update';
const RESEND_NOTIFICATION = '/notifications/resend';
const GET_USER_NOTIFICATION_DETAILS = '/notifications/user/details';
const GET_USER_NOTIFICATION_MATRIX = '/notifications/user/matrix';
const READ_NOTIFICATION = '/notifications/user/read';
const READ_ALL_NOTIFICATION = '/notifications/user/read_all';
const GET_AMOUNT_OF_NOTIFICATIONS = '/notifications/user/amounts';

// contact
const CREATE_CONTACT = '/contacts/create';

// shipping
const GET_ALL_SHIPPING_FEES = '/shipments/fees';
const GET_SHIPMENT_MATRIX = '/shipments/matrix';
const GET_SHIPMENT_DETAILS = '/shipments/details';
const SHIP_ORDER = '/shipments/ship';
const CONFIRM_TO_RECEIVE_ORDER = '/shipments/receive';
const CANCEL_ORDER = '/shipments/cancel';
const IMPORT_FEE = '/shipments/import';

// feedback
const CREATE_FEEDBACK = '/feedbacks/create';
const GET_FEEDBACK_DETAILS = '/feedbacks/details';
const GET_FEEDBACK_MATRIX = '/feedbacks/matrix';
const RESOLVE_FEEDBACKS = '/feedbacks/resolve';
const GET_FEEDBACK_CATEGORY_MATRIX = '/feedbacks/categories/matrix';
const DELETE_FEEDBACK_CATEGORIES = '/feedbacks/categories/delete';
const UPDATE_FEEDBACK_CATEGORY = '/feedbacks/categories/update';
const CREATE_FEEDBACK_CATEGORY = '/feedbacks/categories/create';

// configuration
const GET_CONFIGURATION_DETAILS = '/configurations/details';
const UPDATE_CONFIGURATION = '/configurations/update';

export const apiConstant = {
   API_URL,

   GET_ME,
   LOGIN,
   LOGOUT,
   UPDATE_PROFILE,

   GET_CATEGORIES_MATRIX,
   CREATE_CATEGORY,
   UPDATE_CATEGORY,
   DELETE_CATEGORY,

   GET_TAG_MATRIX,
   CREATE_TAG,
   UPDATE_TAG,
   DELETE_TAG,

   GET_PRODUCTS_MATRIX,
   CREATE_PRODUCT,
   UPDATE_PRODUCT,
   DELETE_PRODUCT,
   RECOVER_PRODUCT,
   GET_PRODUCT_DETAILS,
   UPDATE_PRODUCT_STATUSES,

   GET_ATTACHMENTS_MATRIX,
   UPLOAD_ATTACHMENTS,
   DELETE_ATTACHMENTS,
   GET_ATTACHMENT_BY_UID,

   GET_OPTION_TYPE_MATRIX,
   CREATE_OPTION_TYPE,
   UPDATE_OPTION_TYPE,
   GET_OPTION_MATRIX,
   CREATE_OPTION,
   UPDATE_OPTION,
   DELETE_OPTION,

   CREATE_PRICE_TYPE,
   UPDATE_PRICE_TYPE,
   DELETE_PRICE_TYPE,
   GET_PRICE_TYPE_MATRIX,

   CREATE_USER,
   UPDATE_USER,
   DELETE_USER,
   RECOVER_USER,
   LOCK_USER,
   UNLOCK_USER,
   RESET_PASSWORD,
   CHANGE_PASSWORD,
   REQUEST_ACTIVATION,
   ACTIVATE_USER,
   GET_USER_MATRIX,

   GET_REVIEW_MATRIX,
   CREATE_REVIEW,
   LOCK_REVIEW,
   UNLOCK_REVIEW,
   DELETE_REVIEW,

   GET_LOCAL_CART_DETAILS,

   GET_SELLER_DETAILS,
   REGISTER_SELLER,
   DELETE_SELLER,

   CREATE_CARD,
   SET_DEFAULT_CARD,
   GET_ALL_CARD,
   DELETE_CARD,
   CREATE_CHECKOUT,

   CREATE_ADDRESS,
   UPDATE_ADDRESS,
   DELETE_ADDRESSES,
   GET_ADDRESSES_MATRIX,
   SET_DEFAULT_ADDRESS,

   ADD_PRODUCT_TO_WISHLIST,
   DELETE_FROM_WISHLIST,
   GET_WISHLIST_MATRIX,

   GET_EVENT_MATRIX,
   DELETE_EVENT,
   LOCK_EVENT,
   UNLOCK_EVENT,
   GET_EVENT_DETAILS,
   CREATE_EVENT,
   UPDATE_EVENT,
   CHECK_COUPON,

   GET_NOTIFICATIONS_MATRIX,
   GET_NOTIFICATION_DETAILS,
   CREATE_NOTIFICATION,
   DELETE_NOTIFICATIONS,
   UPDATE_NOTIFICATION,
   RESEND_NOTIFICATION,
   GET_USER_NOTIFICATION_DETAILS,
   GET_USER_NOTIFICATION_MATRIX,
   READ_NOTIFICATION,
   READ_ALL_NOTIFICATION,
   GET_AMOUNT_OF_NOTIFICATIONS,

   CREATE_CONTACT,
   GET_ALL_SHIPPING_FEES,
   GET_SHIPMENT_MATRIX,
   GET_SHIPMENT_DETAILS,
   SHIP_ORDER,
   CONFIRM_TO_RECEIVE_ORDER,
   CANCEL_ORDER,
   IMPORT_FEE,

   CREATE_FEEDBACK,
   GET_FEEDBACK_DETAILS,
   GET_FEEDBACK_MATRIX,
   RESOLVE_FEEDBACKS,
   GET_FEEDBACK_CATEGORY_MATRIX,
   DELETE_FEEDBACK_CATEGORIES,
   UPDATE_FEEDBACK_CATEGORY,
   CREATE_FEEDBACK_CATEGORY,

   GET_CONFIGURATION_DETAILS,
   UPDATE_CONFIGURATION,
};
