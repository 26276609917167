import { FETCH_CATEGORIES } from '../constants/actions';
import { dispatch } from '@store/store';
import Request from './utils/Request';

const loadCategories = async (body) => {
   return await dispatch(
      Request.post(FETCH_CATEGORIES, {
         data: body,
      })
   );
};

export const categoryService = {
   loadCategories,
};
