// import Action from "./Action";
import {
   GET_ME__FULFILLED,
   SET_ERROR,
   SAVE_TO_MEDIA,
   DELETE_FROM_MEDIA,
   SET_COUNTRY_DATA,
   SET_DEVICE_TOKEN,
   UPDATE_ITEM_IN_LOCAL_CART,
   PUSH_ITEM_TO_LOCAL_CART,
   REMOVE_ITEM_FROM_LOCAL_CART,
   CLEAR_CART_ERROR,
   CLEAR_LOCAL_CART,
} from '../constants/actions';
import { dispatch, getState } from './store';

// error
const resetError = () => {
   dispatch({
      type: SET_ERROR,
      errorCode: null,
   });
};

// profile
const removeUserProfile = () => {
   dispatch({
      type: GET_ME__FULFILLED,
      payload: null,
   });
};

// media
const saveToMedia = (data) => {
   dispatch({
      type: SAVE_TO_MEDIA,
      payload: data,
   });
};

const deleteFromMedia = (data) => {
   dispatch({
      type: DELETE_FROM_MEDIA,
      payload: data,
   });
};

const getMediaItem = (uid) => {
   return getState()?.media?.data?.[uid] || null;
};

// country
const setCountry = (data) => {
   dispatch({
      type: SET_COUNTRY_DATA,
      payload: data,
   });
};
// device token
const setDeviceToken = (data) => {
   dispatch({
      type: SET_DEVICE_TOKEN,
      payload: data,
   });
};

// cart
const updateToLocalCart = (item) => {
   dispatch({
      type: UPDATE_ITEM_IN_LOCAL_CART,
      payload: item,
   });
};
const addToLocalCart = (item) => {
   dispatch({
      type: PUSH_ITEM_TO_LOCAL_CART,
      payload: item,
   });
};
const removeFromLocalCart = (itemId) => {
   dispatch({
      type: REMOVE_ITEM_FROM_LOCAL_CART,
      payload: itemId,
   });
};
const clearCartError = () => {
   dispatch({
      type: CLEAR_CART_ERROR,
   });
};
const clearLocalCart = () => {
   dispatch({
      type: CLEAR_LOCAL_CART,
   });
};

export const storeDispatcher = {
   resetError,
   removeUserProfile,
   saveToMedia,
   deleteFromMedia,
   getMediaItem,
   setCountry,
   setDeviceToken,
   updateToLocalCart,
   addToLocalCart,
   removeFromLocalCart,
   clearCartError,
   clearLocalCart,
};
