// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[id]-js": () => import("./../../../src/pages/[id].js" /* webpackChunkName: "component---src-pages-[id]-js" */),
  "component---src-pages-activate-[code]-js": () => import("./../../../src/pages/activate/[code].js" /* webpackChunkName: "component---src-pages-activate-[code]-js" */),
  "component---src-pages-activate-index-js": () => import("./../../../src/pages/activate/index.js" /* webpackChunkName: "component---src-pages-activate-index-js" */),
  "component---src-pages-admin-categories-index-js": () => import("./../../../src/pages/admin/categories/index.js" /* webpackChunkName: "component---src-pages-admin-categories-index-js" */),
  "component---src-pages-admin-events-[id]-js": () => import("./../../../src/pages/admin/events/[id].js" /* webpackChunkName: "component---src-pages-admin-events-[id]-js" */),
  "component---src-pages-admin-events-index-js": () => import("./../../../src/pages/admin/events/index.js" /* webpackChunkName: "component---src-pages-admin-events-index-js" */),
  "component---src-pages-admin-events-new-index-js": () => import("./../../../src/pages/admin/events/new/index.js" /* webpackChunkName: "component---src-pages-admin-events-new-index-js" */),
  "component---src-pages-admin-feedback-[id]-js": () => import("./../../../src/pages/admin/feedback/[id].js" /* webpackChunkName: "component---src-pages-admin-feedback-[id]-js" */),
  "component---src-pages-admin-feedback-categories-index-js": () => import("./../../../src/pages/admin/feedback/categories/index.js" /* webpackChunkName: "component---src-pages-admin-feedback-categories-index-js" */),
  "component---src-pages-admin-feedback-index-js": () => import("./../../../src/pages/admin/feedback/index.js" /* webpackChunkName: "component---src-pages-admin-feedback-index-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-notifications-index-js": () => import("./../../../src/pages/admin/notifications/index.js" /* webpackChunkName: "component---src-pages-admin-notifications-index-js" */),
  "component---src-pages-admin-options-index-js": () => import("./../../../src/pages/admin/options/index.js" /* webpackChunkName: "component---src-pages-admin-options-index-js" */),
  "component---src-pages-admin-products-[id]-js": () => import("./../../../src/pages/admin/products/[id].js" /* webpackChunkName: "component---src-pages-admin-products-[id]-js" */),
  "component---src-pages-admin-products-index-js": () => import("./../../../src/pages/admin/products/index.js" /* webpackChunkName: "component---src-pages-admin-products-index-js" */),
  "component---src-pages-admin-products-new-index-js": () => import("./../../../src/pages/admin/products/new/index.js" /* webpackChunkName: "component---src-pages-admin-products-new-index-js" */),
  "component---src-pages-admin-setting-index-js": () => import("./../../../src/pages/admin/setting/index.js" /* webpackChunkName: "component---src-pages-admin-setting-index-js" */),
  "component---src-pages-admin-shipment-fees-index-js": () => import("./../../../src/pages/admin/shipment/fees/index.js" /* webpackChunkName: "component---src-pages-admin-shipment-fees-index-js" */),
  "component---src-pages-admin-shipment-index-js": () => import("./../../../src/pages/admin/shipment/index.js" /* webpackChunkName: "component---src-pages-admin-shipment-index-js" */),
  "component---src-pages-admin-tags-index-js": () => import("./../../../src/pages/admin/tags/index.js" /* webpackChunkName: "component---src-pages-admin-tags-index-js" */),
  "component---src-pages-admin-users-index-js": () => import("./../../../src/pages/admin/users/index.js" /* webpackChunkName: "component---src-pages-admin-users-index-js" */),
  "component---src-pages-admin-wholesale-types-index-js": () => import("./../../../src/pages/admin/wholesale-types/index.js" /* webpackChunkName: "component---src-pages-admin-wholesale-types-index-js" */),
  "component---src-pages-cart-index-js": () => import("./../../../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-change-password-[code]-js": () => import("./../../../src/pages/change-password/[code].js" /* webpackChunkName: "component---src-pages-change-password-[code]-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-feedback-index-js": () => import("./../../../src/pages/feedback/index.js" /* webpackChunkName: "component---src-pages-feedback-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-profile-info-index-js": () => import("./../../../src/pages/profile/info/index.js" /* webpackChunkName: "component---src-pages-profile-info-index-js" */),
  "component---src-pages-profile-notifications-index-js": () => import("./../../../src/pages/profile/notifications/index.js" /* webpackChunkName: "component---src-pages-profile-notifications-index-js" */),
  "component---src-pages-profile-order-index-js": () => import("./../../../src/pages/profile/order/index.js" /* webpackChunkName: "component---src-pages-profile-order-index-js" */),
  "component---src-pages-profile-wishlist-index-js": () => import("./../../../src/pages/profile/wishlist/index.js" /* webpackChunkName: "component---src-pages-profile-wishlist-index-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-reset-password-index-js": () => import("./../../../src/pages/reset-password/index.js" /* webpackChunkName: "component---src-pages-reset-password-index-js" */),
  "component---src-pages-shipping-policy-index-js": () => import("./../../../src/pages/shipping-policy/index.js" /* webpackChunkName: "component---src-pages-shipping-policy-index-js" */)
}

