import React from 'react';

const IconAdmin = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M12.2981 14.734C11.682 14.916 10.954 15 10.1 15H5.90003C5.04603 15 4.31803 14.916 3.70203 14.734C3.85603 12.914 5.72503 11.479 8.00004 11.479C10.275 11.479 12.1441 12.914 12.2981 14.734Z"
         stroke="currentColor"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path
         d="M10.1 1H5.89995C2.39994 1 0.999939 2.4 0.999939 5.90001V10.1C0.999939 12.746 1.79794 14.195 3.70195 14.734C3.85595 12.914 5.72495 11.479 7.99996 11.479C10.275 11.479 12.144 12.914 12.298 14.734C14.202 14.195 15 12.746 15 10.1V5.90001C15 2.4 13.6 1 10.1 1ZM7.99996 9.51901C6.61396 9.51901 5.49395 8.39203 5.49395 7.00603C5.49395 5.62003 6.61396 4.50001 7.99996 4.50001C9.38596 4.50001 10.506 5.62003 10.506 7.00603C10.506 8.39203 9.38596 9.51901 7.99996 9.51901Z"
         stroke="currentColor"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path
         d="M10.506 7.00599C10.506 8.39199 9.38603 9.51897 8.00003 9.51897C6.61402 9.51897 5.49402 8.39199 5.49402 7.00599C5.49402 5.61998 6.61402 4.49997 8.00003 4.49997C9.38603 4.49997 10.506 5.61998 10.506 7.00599Z"
         stroke="currentColor"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
   </svg>
);

export default IconAdmin;