import React from 'react';
import { message as antdMessage } from 'antd';
import Loadable from 'react-loadable';

const defaultDuration = 3;

antdMessage.config({
   top: 16,
   duration: defaultDuration,
   maxCount: 1,
   prefixCls: 'vgz-message-container ant-message',
});

const IconError = Loadable({
   loader: () => import('../Icons/IconError'),
   loading: () => null,
});
const IconSuccess = Loadable({
   loader: () => import('../Icons/IconSuccess'),
   loading: () => null,
});
const IconInfo = Loadable({
   loader: () => import('../Icons/IconInfo'),
   loading: () => null,
});

const error = (msg) => {
   antdMessage.error({
      content: msg || '',
      className: 'vgz-message error',
      icon: <IconError />,
   });
};

const success = (msg) => {
   antdMessage.success({
      content: msg || '',
      className: 'vgz-message success',
      icon: <IconSuccess />,
   });
};

const info = (msg) => {
   antdMessage.success({
      content: msg || '',
      className: 'vgz-message info',
      icon: <IconInfo />,
   });
};

const loading = (msg, duration) => {
   antdMessage.loading({
      content: msg || '',
      className: 'vgz-message loading',
      // icon: <IconInfo />,
      duration: duration ?? defaultDuration,
   });
};

const destroy = () => {
   antdMessage.destroy();
};

export const message = {
   error,
   success,
   info,
   loading,
   destroy,
};
