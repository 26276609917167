import * as actions from '@constants/actions';

const initialState = {
   data: {
      content: [],
   },
   popupData: {
      content: [],
      amount: {
         unread: 0,
         total: 0,
      },
   },
   loading: false,
   error: null,
};

const updateData = (currentData, payload) => {
   if (payload?.content) {
      return { ...payload };
   }
   return currentData;
};

const updatePopupData = (currentPopupData, payload) => {
   if (payload?.content && payload?.currentPage === 1) {
      return {
         ...currentPopupData,
         content: payload.content,
      };
   }
   return currentPopupData;
};

const updateAmount = (currentAmount, payload) => {
   if (payload?.total !== void 0) {
      return payload;
   }
   return currentAmount;
};

const notificationsReducer = (state = initialState, action) => {
   switch (action.type) {
      case actions.FETCH_USER_NOTIFICATIONS__PENDING:
         return {
            ...state,
            loading: true,
            error: null,
         };

      case actions.FETCH_USER_NOTIFICATIONS__FULFILLED:
         return {
            ...state,
            data: updateData(state.data, action.payload),
            popupData: updatePopupData(state.popupData, action.payload),
            loading: false,
            error: null,
         };
      case actions.FETCH_USER_NOTIFICATIONS__REJECTED:
         return {
            ...state,
            loading: false,
            error: action.payload,
         };

      case actions.FETCH_USER_NOTIFICATIONS_AMOUNT__FULFILLED:
         return {
            ...state,
            popupData: {
               ...state.popupData,
               amount: updateAmount(state.popupData.amount, action.payload),
            },
         };

      case actions.FETCH_POPUP_USER_NOTIFICATIONS__FULFILLED:
         return {
            ...state,
            popupData: updatePopupData(state.popupData, action.payload),
         };

      default:
         return state;
   }
};

export default notificationsReducer;
