// import { initializeApp } from 'firebase/app';

import { Config } from '.';

const firebaseConfig = {
   apiKey: Config.FCM_API_KEY,
   authDomain: Config.FCM_AUTH_DOMAIN,
   projectId: Config.FCM_PROJECT_ID,
   storageBucket: Config.FCM_STORAGE_BUCKET,
   messagingSenderId: Config.FCM_SENDER_ID,
   appId: Config.FCM_APP_ID,
   measurementId: Config.FCM_MEASUREMENT_ID,
};

let firebaseApp;
let analytics;
let messaging;

export const getFirebase = (firebase) => {
   console.log(firebase);
   firebase.initializeApp(firebaseConfig);
   firebaseApp = firebase;
};

export const getAnalytics = (firebaseAnalytics) => {
   if (analytics) return analytics;
   analytics = firebaseApp.analytics();
   return analytics;
};

export const getMessaging = () => {
   if (messaging) return messaging;
   messaging = firebaseApp.messaging();
   return messaging;
};
