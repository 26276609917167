import {
   ADD_TO_CART,
   FETCH_CART,
   UPDATE_CART_ITEM,
   DELETE_CART_ITEM,
} from '../constants/actions';
import { dispatch } from '@store/store';
import Request from './utils/Request';
import { tokenUtil } from '@utils/token';
import { apiConstant } from '@apis/constant';
import { fetch } from '@apis/ServiceController';
import { storeDispatcher } from '@store/dispatcher';

const add = async (body) => {
   return await dispatch(
      Request.post(ADD_TO_CART, {
         data: body,
      })
   );
};

const getDetails = async () => {
   return await dispatch(Request.get(FETCH_CART));
};

const updateCartItem = async (uid, body) => {
   return await dispatch(
      Request.put(UPDATE_CART_ITEM, {
         params: {
            uid,
         },
         data: body,
      })
   );
};

const deleteCartItem = async (body) => {
   return await dispatch(
      Request.delete(DELETE_CART_ITEM, {
         data: body,
      })
   );
};

const getLocalCartDetails = async (body) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_LOCAL_CART_DETAILS,
      data: body,
      headers: {
         'Content-Type': 'application/json',
      },
   });
};

const syncLocalCart = async (localCartItems) => {
   if (localCartItems?.length) {
      const items = localCartItems.filter((i) => i.item_id);
      if (items?.length) {
         await cartService.add({
            items: items,
         });
      }
   }
};

export const cartService = {
   add,
   getDetails,
   updateCartItem,
   deleteCartItem,
   getLocalCartDetails,
   syncLocalCart,
};
