import { CurrencyUnit } from './enums/CurrencyUnit';
import { ProductDisplayStatus } from './enums/ProductDisplayStatus';
import { ProductStatus } from './enums/ProductStatus';
import _isNumber from 'lodash/isNumber';

/**
 * Toggle modal class
 * @param {*} isOpen
 */
const setModalOpen = (isOpen) => {
   const bodyModalClassname = 'modal-open';
   if (isOpen) {
      if (!document.body.classList.contains(bodyModalClassname)) {
         document.body.classList.add(bodyModalClassname);
      }
   } else {
      if (document.body.classList.contains(bodyModalClassname)) {
         const pattern = new RegExp('(?:^|\\s)' + bodyModalClassname + '(?:\\s|$)', 'g');
         document.body.className = document.body.className.replace(pattern, '');
      }
   }
};

/**
 * Copy text to clipboard
 * @param {*} text
 * @returns {isSuccess, error}
 */
const copyToClipboard = (text) => {
   const textField = document.createElement('textarea');
   textField.innerText = text;
   document.body.appendChild(textField);
   textField.select();
   const response = {
      isSuccess: false,
      error: '',
   };
   try {
      const successful = document.execCommand('copy');
      if (successful) {
         response.isSuccess = true;
      }
   } catch (err) {
      response.error = err;
   }
   textField.remove();
   return response;
};

const selectTextAtNode = (nodeRef) => {
   const response = {
      isSuccess: false,
      error: '',
   };
   if (nodeRef) {
      if (document.body.createTextRange) {
         const range = document.body.createTextRange();
         range.moveToElementText(nodeRef);
         range.select();
         response.isSuccess = true;
         return response;
      } else if (typeof window !== 'undefined' && window.getSelection) {
         const selection = window.getSelection();
         const range = document.createRange();
         range.selectNodeContents(nodeRef);
         selection.removeAllRanges();
         selection.addRange(range);
         response.isSuccess = true;
         return response;
      } else {
         response.error = 'Could not select text in node: Unsupported browser.';
      }
   }
   return response;
};

const getProductDisplayStatus = (productInfo) => {
   if (productInfo) {
      const { favorite, price, quantity, status } = productInfo;
      if (quantity === 0 && status === ProductStatus.SOLD) {
         return ProductDisplayStatus.SOLD_OUT;
      }
   }
   return '';
};

const getCurrency = (price, unit) => {
   if (!_isNumber(price) && !price) return '';
   let locale = 'en-US';
   if (unit === CurrencyUnit.VND) {
      locale = 'vi-VN';
   }
   const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: unit,
   });
   return formatter.format(price);
};

export const functionUtils = {
   setModalOpen,
   copyToClipboard,
   selectTextAtNode,
   getProductDisplayStatus,
   getCurrency,
};
