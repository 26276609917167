import { dispatch } from '@store/store';
import { GET_ME } from '../constants/actions';
import { storeDispatcher } from '../store/dispatcher';
import { tokenUtil } from '../utils/token';
import Request from './utils/Request';

const loadUserProfile = async () => {
   return await dispatch(Request.get(GET_ME));
};

const removeUserProfile = () => {
   storeDispatcher.removeUserProfile(null);
};

export const profileService = {
   loadUserProfile,
   removeUserProfile,
};
