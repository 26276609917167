export const Config = {
   API_URL: 'https://apis.vgzcustom.com',
   FCM_PUBLIC_VAPI_KEY:
      'BPnKEtWJOqyBIUTYpBHJ7g-FSsTFuUCWz6G4eSOB1o82SgkVns5AuPQovlgrINjp-1f6ZJgeb1sl4oW9QU6rR7c',
   FCM_API_KEY: 'AIzaSyCwT5uco75V4G2Ajs5IUCYamnx_K3z3ULo',
   FCM_AUTH_DOMAIN: 'vgz-custom.firebaseapp.com',
   FCM_PROJECT_ID: 'vgz-custom',
   FCM_STORAGE_BUCKET: 'vgz-custom.appspot.com',
   FCM_SENDER_ID: '436103637818',
   FCM_APP_ID: '1:436103637818:web:32ca62a18dec5b42987e03',
   FCM_MEASUREMENT_ID: 'G-8VHR440EYM',
};
