import { tokenUtil } from '../utils/token';
import { apiCommon } from './common';
import { apiConstant } from './constant';
import ResponseModel from './models/ResponseModel';
import { fetch } from './ServiceController';
import _forIn from 'lodash/forIn';

const getMe = async () => {
   return fetch({
      method: 'get',
      url: apiConstant.GET_ME,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
   });
};

const login = async (body) => {
   return fetch({
      method: 'post',
      url: apiConstant.LOGIN,
      headers: {
         'Content-Type': 'application/json',
      },
      data: body,
   });
};

const logout = async () => {
   const accessToken = tokenUtil.getToken();
   if (accessToken) {
      return fetch({
         method: 'get',
         url: apiConstant.LOGOUT,
         headers: {
            'Content-Type': 'application/json',
            Authorization: tokenUtil.getToken(),
         },
      });
   } else {
      const response = new ResponseModel();
      response.isSuccess = true;
      response.errorCode = 0;
      response.message = '';
      response.data = true;
      return response;
   }
};

const updateProfile = async (body) => {
   const formData = new FormData();
   if (body) {
      _forIn(body, (value, key) => {
         formData.append(key, value || '');
      });
   }
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_PROFILE,
      headers: {
         Authorization: tokenUtil.getToken(),
      },
      data: formData,
   });
};

export const profileApi = {
   getMe,
   login,
   logout,
   updateProfile,
};
